import { compact, map, size } from 'lodash-es'
import { ValidationValueMessage } from 'react-hook-form'

import {
  BlockSetMonadic,
  chooseMonadicConceptForQuestion,
} from './questionBlocks'
import { carryForward } from './carryForward'
import { displayXOfYQuestionResources } from './displayXOfY'
import {
  filterConceptsByDisplayLogic,
  filterQuestionByDisplayLogic,
  filterQuestionResourcesByDisplayLogic,
} from './displayLogic'
import {
  getQuestion as getQuestionBipolar,
  isBipolarQuestion,
  Question as QuestionBipolar,
} from './bipolar'
import {
  getDisplayError as getDisplayErrorIdeaPresenter,
  getQuestion as getQuestionIdeaPresenter,
  isIdeaPresenterQuestion,
  Question as QuestionIdeaPresenter,
} from './ideaPresenter'
import {
  getDisplayError as getDisplayErrorMatrix,
  getQuestion as getQuestionMatrix,
  Question as QuestionMatrix,
} from './matrix'
import {
  getDisplayError as getDisplayErrorMultipleChoice,
  getQuestion as getQuestionMultipleChoice,
  Question as QuestionMultipleChoice,
} from './multipleChoice'
import {
  getFormattedValue,
  getQuestion as getQuestionGaborGranger,
  Question as QuestionGaborGranger,
} from './gaborGranger'
import {
  getQuestion as getQuestionOpenEnded,
  isOpenEndedQuestion,
  Question as QuestionOpenEnded,
} from './openEnded'
import {
  getDisplayError as getDisplayErrorRanking,
  getQuestion as getQuestionRanking,
  Question as QuestionRanking,
} from './ranking'
import {
  getDisplayError as getDisplayErrorScale,
  getQuestion as getQuestionScale,
  Question as QuestionScale,
} from './scale'
import {
  getQuestion as getQuestionUnaidedAwareness,
  isUnaidedAwarenessQuestion,
  Question as QuestionUnaidedAwareness,
} from './unaidedAwareness'
import {
  Label,
  LabelFeatureCode,
  Question as QuestionAPI,
  QuestionFeatureCode,
  QuestionFeatureCodeWithNumberRange,
  QuestionFeatureCodeWithRegex,
} from 'types/glass-api/domainModels'
import { pipeChoicesIntoTitle, pipeConcept, titleHasPipes } from './piping'
import { randomizeQuestionResources } from './randomize'
import { selectRandomConcept } from './concepts'
import { Survey, SurveyItem } from './surveys'
import { titlesFromSet } from './general'
import { track } from './analytics'

export type Question =
  | QuestionBipolar
  | QuestionGaborGranger
  | QuestionIdeaPresenter
  | QuestionMatrix
  | QuestionMultipleChoice
  | QuestionOpenEnded
  | QuestionRanking
  | QuestionScale
  | QuestionUnaidedAwareness

// For monadic blocks, the question ID will contain a number that indicates which
// iteration the question is for. For example, a question with ID 1023 that is in
// a monadic block set with two iterations will have IDs: 1023-0 and 1023-1.
const ID_ITERATION_DELIMITER = '-'

export const OPTION_TYPES = {
  IMAGE: 1,
  TEXT: 2,
}

export const QUESTION_TYPES = {
  GABOR_GRANGER: 14,
  MATRIX: 12,
  MULTIPLE_CHOICE: 2,
  OPEN_ENDED: [3, 10],
  RANKING: 7,
  SCALE: 6,
}

export function applyQuestionFeatures({
  monadicBlocks,
  nextQuestion,
  nextSurveyItem,
  questions,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  nextQuestion: Question
  nextSurveyItem: SurveyItem
  questions: Record<string, Question>
}) {
  let question = nextQuestion

  question = pipeConcept({ nextQuestion: question, questions })
  question = filterConceptsByDisplayLogic({
    monadicBlocks,
    nextQuestion: question,
    questions,
  })
  if (nextSurveyItem.type === 'blockSetMonadic') {
    question = chooseMonadicConceptForQuestion({
      blockSetMonadic: nextSurveyItem.data,
      nextQuestion: question,
      questions,
    })
  } else {
    question = selectRandomConcept({ nextQuestion: question })
  }

  question = carryForward({ nextQuestion: question, questions })
  question = pipeChoicesIntoTitle({ nextQuestion: question, questions })
  question = filterQuestionResourcesByDisplayLogic({
    monadicBlocks,
    nextQuestion: question,
    questions,
  })
  question = displayXOfYQuestionResources({ nextQuestion: question })
  question = randomizeQuestionResources({ nextQuestion: question })

  return question
}

/**
 * Builds a label to display for questions in the testing interface. Labels can be
 * useful to summarize the main point of the question for testing / export purposes.
 * The label is not meant for display to the respondent.
 */
export function buildTestLabel(question: QuestionAPI) {
  let label = ''

  if (question.isScreener) {
    label = 'QA SCREEN'
  } else if (question.isStandard) {
    label = `DEMO ${question.sort}`
  } else {
    label = `Q${question.sort}`
  }

  if (question.label) {
    label = `${label} [${question.label}]`
  }

  return label
}

/**
 * Returns all previous questions for the specified question ID. Most of the time this will just be
 * a single question, but it could be multiple if the question ID specified is one inside a monadic loop.
 *
 * For example, if the question ID is "1023", and there was a monadic loop with 3 iterations
 * of the question, we will return questions: ["1023-1", "1023-2", "1023-3"].
 */
export function getAllPreviousQuestions({
  previousQuestionID,
  questions,
}: {
  previousQuestionID: string
  questions: Record<string, Question>
}) {
  return compact(
    map(questions, (question, questionID) => {
      const id = getIDParts({ id: questionID })[0]

      return id === previousQuestionID ? question : undefined
    }),
  )
}

/**
 * Returns the min-max configuration for a question constraint if it exists.
 * Note: Unlike the min-max for a multiple choice question, both the min and
 * max are required if the constraint exists.
 */
export function getConstraintMinMax(question: QuestionAPI) {
  if (question.constraint && 'range' in question.constraint) {
    const { end: max, start: min } = question.constraint.range

    return {
      max: {
        message: `Must be at most ${max}.`,
        value: max,
      } satisfies ValidationValueMessage<number>,
      min: {
        message: `Must be at least ${min}.`,
        value: min,
      } satisfies ValidationValueMessage<number>,
    }
  }

  return { max: undefined, min: undefined }
}

export function getConstraintTextRegExp(question: QuestionAPI) {
  if (
    question.constraint &&
    'regex' in question.constraint &&
    // This is so sad but our regex for emails doesn't work so we can't apply that
    // in this code. We use the browser "email" input type instead of a custom regex.
    // The only way to identify this constraint as the email one is by its error message.
    question.constraint.errorMessage !== 'Must be a valid email.'
  ) {
    const { errorMessage, regex } = question.constraint

    return {
      message: errorMessage,
      value: new RegExp(regex, 'i'),
    } satisfies ValidationValueMessage<RegExp>
  }

  return undefined
}

/**
 * Returns a string description of an error when considering if a question is valid
 * for displaying to the respondent. For example, a question may not be displayable
 * if it's filtered out by display logic or has no options to display. If the question
 * is valid, false is returned.
 *
 * A string is returned here for display in the testing interface to inform the survey
 * builder why a question was not shown.
 */
export function getDisplayError({
  monadicBlocks,
  question,
  questions,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  question: Question
  questions: Record<string, Question>
}) {
  if (
    !filterQuestionByDisplayLogic({
      monadicBlocks,
      nextQuestion: question,
      questions,
    })
  ) {
    return 'display logic'
  }

  if (titleHasPipes(question.title)) {
    track('Skipped Question', {
      questionID: question.id,
      questionTitle: question.title,
      reason: 'unresolvedPipes',
    })

    return 'unresolved pipes'
  }

  if (question.type === 'ideaPresenter') {
    return getDisplayErrorIdeaPresenter({ question })
  } else if (question.type === 'matrix') {
    return getDisplayErrorMatrix({ question })
  } else if (question.type === 'multipleChoice') {
    return getDisplayErrorMultipleChoice({ question })
  } else if (question.type === 'ranking') {
    return getDisplayErrorRanking({ question })
  } else if (question.type === 'scale') {
    return getDisplayErrorScale({ question })
  } else if (
    question.type === 'bipolar' ||
    question.type === 'gaborGranger' ||
    question.type === 'openEnded' ||
    question.type === 'unaidedAwareness'
  ) {
    return false
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The thrown error is to protect against when we add a new question type and forget to add it here.
  // @ts-expect-error
  throw new Error(`Unsupported question type: ${question.type}`)
}

/**
 * Maps the data from a question from the API into only the fields that are needed
 * to display the question to the respondent. Hopefully one day we can remove this
 * function if we build a dedicated endpoint for returning survey response question data.
 */
export function getQuestion({
  id,
  question,
  questionsConfiguration,
  survey,
}: {
  id: string
  question: QuestionAPI
  questionsConfiguration: Record<number, QuestionAPI>
  survey: Survey
}): Question {
  if (isBipolarQuestion(question)) {
    return getQuestionBipolar({ id, question })
  } else if (question.questionTypeId === QUESTION_TYPES.GABOR_GRANGER) {
    return getQuestionGaborGranger({ id, question })
  } else if (isIdeaPresenterQuestion(question)) {
    return getQuestionIdeaPresenter({ id, question })
  } else if (question.questionTypeId === QUESTION_TYPES.MATRIX) {
    return getQuestionMatrix({
      id,
      question,
      questionsConfiguration,
      survey,
    })
  } else if (question.questionTypeId === QUESTION_TYPES.MULTIPLE_CHOICE) {
    if (isUnaidedAwarenessQuestion(question)) {
      return getQuestionUnaidedAwareness({ id, question })
    }

    return getQuestionMultipleChoice({
      id,
      question,
      questionsConfiguration,
      survey,
    })
  } else if (isOpenEndedQuestion(question)) {
    return getQuestionOpenEnded({ id, question })
  } else if (question.questionTypeId === QUESTION_TYPES.RANKING) {
    return getQuestionRanking({
      id,
      question,
      questionsConfiguration,
      survey,
    })
  } else if (question.questionTypeId === QUESTION_TYPES.SCALE) {
    return getQuestionScale({
      id,
      question,
      questionsConfiguration,
      useNewMatrixOptions: survey.features.useNewMatrixOptions,
    })
  }

  throw new Error(`Unsupported question type: ${question.questionType.name}`)
}

export function getFeature({
  code,
  question,
}: {
  code: QuestionFeatureCode
  question: QuestionAPI
}) {
  return question.questionFeatures.find(({ feature }) => {
    return feature.code === code
  })
}

/**
 * Gets the enum value from a question feature, which typically represents the question
 * ID for the specified feature. For example, the pipe concept feature uses the enumValue
 * to represent which question the concept should be piped from.
 */
export function getFeatureEnumValue({
  code,
  question,
}: {
  code: QuestionFeatureCode
  question: QuestionAPI
}) {
  const feature = getFeature({ code, question })

  return feature?.enumValue ?? null
}

/**
 * Gets the value from a "number range" question feature. For example, the
 * display X of Y feature uses the numberRange object but only the start value
 * is meaningful (the start and end should be the same value).
 */
export function getFeatureNumberRangeValue({
  code,
  question,
}: {
  code: QuestionFeatureCodeWithNumberRange
  question: QuestionAPI
}) {
  const feature = getFeature({ code, question })

  return feature && 'numberRange' in feature ? feature.numberRange.start : null
}

/**
 * Gets the value from a "regex" question feature. For example, the
 * VoxpopmeFeature01 feature uses the "regex" field to store the Voxpopme
 * project ID.
 */
export function getFeatureRegex({
  code,
  question,
}: {
  code: QuestionFeatureCodeWithRegex
  question: QuestionAPI
}) {
  const feature = getFeature({ code, question })

  return feature && 'regex' in feature ? feature.regex : null
}

/**
 * A question ID can have an iteration appended to it. For example, in monadic
 * blocks, the same questions are repeated several times with a different concept
 * / prompt each iteration.
 *
 * So question IDs can look like this: 90234-0, 90234-1, 90234-2, etc.
 */
export function getIDParts({ id }: { id: string }) {
  return id.split(ID_ITERATION_DELIMITER)
}

/**
 * In monadic blocks, the same questions are repeated several times with a different
 * concept / prompt each iteration. To indicate that, we append the iteration to the
 * question ID. For non-monadic block questions, there is no iteration.
 *
 * So question IDs can look like this: 83742, 39102, 90234-0, 90234-1, 90234-2, etc.
 */
export function getIDWithIteration({
  id,
  iteration,
}: {
  id: number | string
  iteration: string | undefined
}) {
  return `${id}${iteration ? `${ID_ITERATION_DELIMITER}${iteration}` : ''}`
}

export function getIterationFromID(id: string): string | undefined {
  return getIDParts({ id })[1]
}

/**
 * Returns the previous question for the specified question ID. If an iteration is
 * provided, an attempt to find the previous question with the iteration will be made,
 * and the fallback will be the previousQuestionID as provided.
 *
 * For example, if the iteration is "1" and the question ID is "1023", we will try to
 * find question with ID "1023-1". If we don't find it, we'll try to find question "1023".
 */
export function getPreviousQuestionForIteration({
  iteration,
  previousQuestionID,
  questions,
}: {
  iteration?: string
  previousQuestionID: string
  questions: Record<string, Question>
}) {
  const previousQuestionIDWithIteration = getIDWithIteration({
    id: previousQuestionID,
    iteration,
  })

  return (
    questions[previousQuestionIDWithIteration] ?? questions[previousQuestionID]
  )
}

/**
 * Returns a string array of textual representations of the respondent's responses.
 * This is useful in the testing interface for showing what the current responses are.
 */
export function getResponsesArray({ question }: { question: Question }) {
  if (question.type === 'bipolar') {
    if (size(question.response) === 0) {
      return []
    }

    return question.options.map(({ id, title }) => {
      return `${title}: ${question.response[id]}`
    })
  } else if (question.type === 'gaborGranger') {
    return question.response.map(({ value, willingToAccept }) => {
      const formattedValue = getFormattedValue({
        customText: question.display.formatCustomText,
        format: question.display.format,
        unitDecimals: question.display.unitDecimals,
        value,
      })

      return `${formattedValue}: ${willingToAccept}`
    })
  } else if (question.type === 'ideaPresenter' && question.response) {
    return question.concepts.length > 0 ? [question.concepts[0].alt] : []
  } else if (question.type === 'matrix') {
    if (size(question.response) === 0) {
      return []
    }

    return question.statements.map(({ id, title }) => {
      const selectedOptionTitles = titlesFromSet({
        ids: question.response[id] ?? new Set(),
        resources: question.options.map((option) => {
          const freeText = question.responseFreeText[id]?.[option.id] ?? ''

          return {
            id: option.id,
            title: freeText ? `${option.title} (${freeText})` : option.title,
          }
        }),
      })

      return `${title}: ${selectedOptionTitles.join(', ')}`
    })
  } else if (question.type === 'multipleChoice' && question.response.size > 0) {
    return question.options
      .filter(({ id }) => question.response.has(id))
      .map(({ id, title }) => {
        const freeText = question.responseFreeText[id] ?? ''

        return freeText ? `${title} (${freeText})` : title
      })
  } else if (question.type === 'openEnded') {
    return question.response ? [question.response] : []
  } else if (question.type === 'scale') {
    if (size(question.response) === 0) {
      return []
    }

    return question.scales.map(({ id, title }, i) => {
      return `${title ?? `Scale ${i + 1}`}: ${question.response[id]}`
    })
  } else if (question.type === 'ranking') {
    return compact(
      question.response.map((id, i) => {
        const option = question.options.find((option) => option.id === id)

        return option ? `${option.title}: ${i + 1}` : undefined
      }),
    )
  } else if (question.type === 'unaidedAwareness') {
    return question.response.filter((response) => !!response.trim())
  }

  return []
}

/**
 * Returns true if the question has a feature with the specified code.
 */
export function hasFeature({
  code,
  question,
}: {
  code: QuestionFeatureCode
  question: QuestionAPI
}) {
  return !!getFeature({ code, question })
}

/**
 * Returns true if the label has a feature with the specified code.
 */
export function hasLabelFeature({
  code,
  label,
}: {
  code: LabelFeatureCode
  label: Label
}) {
  return !!label.labelFeatures.find(({ feature }) => {
    return feature.code === code
  })
}

export function shouldHideImageTitles(question: QuestionAPI) {
  return (
    question.contentTypeId === OPTION_TYPES.IMAGE &&
    !question.displayOptionDescription
  )
}
