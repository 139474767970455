import { compact, groupBy, map } from 'lodash-es'

import { BlockSetMonadic } from './questionBlocks'
import {
  buildExpectedResponseFromSegmentQuestionConstraints,
  Condition,
  didSatisfyConditionGroups,
} from './conditions'
import { DisqualificationReason } from './disqualifications'
import { filterSurveyByDisplayLogic } from './displayLogic'
import { Question } from './questions'
import {
  Question as QuestionAPI,
  Survey as SurveyAPI,
} from 'types/glass-api/domainModels'
import { Survey, VariableQuota } from './surveys'

export type OptionQuotasConstraint = {
  cantPickOptionIDs: Set<string>
  // This is an array of sets of resource IDs. It's an array because when a user
  // specifies an "all" quota, they're saying the user can choose any of the specified
  // resources. But they could have multiple "all" quotas, which would be AND'ed together.
  mustPickOptionIDs: Set<string>[]
}

/**
 * Converts the API question quotas representation into an array of sets of option IDs.
 * A respondent has to choose at least one option from each set to satisfy the quotas.
 * See hasSatisfiedQuestionQuotas.
 */
export function buildOptionQuotas({
  question,
  survey,
}: {
  question: QuestionAPI
  survey: Survey
}) {
  let cantPickOptionIDs: string[] = []
  const mustPickOptionIDs: OptionQuotasConstraint['mustPickOptionIDs'] = []

  question.questionQuotas.forEach((quota) => {
    let canPickFromOptionIDs: string[] = []
    // Create arrays with the options in the quota and the remaining question
    // options that aren't in the quota.
    const quotaOptionIDs = quota.questionOptions?.map(({ id }) => `${id}`) ?? []
    const nonQuotaOptionIDs: string[] = []
    question.options.forEach((option) => {
      if (!quotaOptionIDs.includes(`${option.id}`)) {
        nonQuotaOptionIDs.push(`${option.id}`)
      }
    })

    if (quota.logicalModifier === 'all') {
      canPickFromOptionIDs = [...canPickFromOptionIDs, ...quotaOptionIDs]
    } else if (quota.logicalModifier === 'at_least') {
      const remainingCompletes =
        survey.respondents.numNeeded - survey.respondents.numCompleted
      const remainingCompletesNeeded =
        quota.numberNeeded - quota.numberCompleted

      if (remainingCompletesNeeded >= remainingCompletes) {
        canPickFromOptionIDs = [...canPickFromOptionIDs, ...quotaOptionIDs]
      }
    } else if (
      quota.logicalModifier === 'none' ||
      (quota.logicalModifier === 'at_most' &&
        quota.numberCompleted >= quota.numberNeeded)
    ) {
      cantPickOptionIDs = [...cantPickOptionIDs, ...quotaOptionIDs]
    }

    if (canPickFromOptionIDs.length > 0) {
      mustPickOptionIDs.push(new Set(canPickFromOptionIDs))
    }
  })

  return cantPickOptionIDs.length > 0 || mustPickOptionIDs.length > 0
    ? { cantPickOptionIDs: new Set(cantPickOptionIDs), mustPickOptionIDs }
    : undefined
}

export function buildVariableQuotas({ survey }: { survey: SurveyAPI }) {
  // A variable is made up of a group of segments, which are conditions set on
  // questions in the survey. A user can then choose to apply quotas to those
  // segments. We flatten this structure into a list of quotas, each with an
  // associated list of segments.
  const variableQuotas = compact(
    survey.surveyVariables.flatMap(({ quotas, segments, title }) => {
      return quotas.map((quota) => {
        // An "at_most" quota that isn't yet completed is not a quota we
        // need to enforce yet.
        if (
          quota.type === 'at_most' &&
          quota.numberCompleted < quota.numberNeeded
        ) {
          return
        }

        const segmentsForQuota = segments.filter(
          ({ quotaId }) => quotaId === quota.id,
        )
        if (segmentsForQuota.length === 0) {
          return
        }

        return { quota, segments: segmentsForQuota, title }
      })
    }),
  )

  return variableQuotas.map(({ quota, segments, title }) => {
    return {
      segments: segments.map((segment) => {
        const andGroupings = groupBy(segment.questions, 'andGrouping')

        return {
          conditionGroups: {
            groupLogic: 'or',
            groups: map(andGroupings, (grouping) => {
              return grouping.map(
                ({ constraints, logicalModifier, questionId }) => {
                  return {
                    expectedResponse:
                      buildExpectedResponseFromSegmentQuestionConstraints(
                        constraints,
                      ),
                    item: {
                      conceptId: null,
                      id: `${questionId}`,
                      type: 'question',
                    },
                    logic: logicalModifier,
                  } satisfies Condition
                },
              )
            }),
          },
          quotaType: quota.type,
          title: segment.title,
        }
      }),
      title,
    }
  }) satisfies VariableQuota[]
}

export function getQuotaDisqualification({
  curQuestion,
  monadicBlocks,
  questions,
  survey,
}: {
  curQuestion: Question
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  survey: Survey
}) {
  // The demographic quotas are represented as an audience, which is essentially display logic,
  // on the survey itself. I like to think of it like: If the conditions don't pass, the user
  // shouldn't be shown the survey anymore and should be disqualified.
  const surveyPassesDemographicQuotas = filterSurveyByDisplayLogic({
    monadicBlocks,
    questions,
    survey,
  })
  if (!surveyPassesDemographicQuotas) {
    return {
      source: 'glass',
      tactic: 'audience_quota',
    } satisfies DisqualificationReason
  }

  const satisfiesAllVariableQuotas = survey.quotas.variables.every(
    ({ segments }) => {
      return segments.some(({ conditionGroups, quotaType }) => {
        const fitsIntoSegment = didSatisfyConditionGroups({
          conditionGroups,
          monadicBlocks,
          questions,
          // If a respondent hasn't yet seen a question, we only want to put them into the segment
          // for "all" quota types. Otherwise, if a respondent is placed into a segment for a "none"
          // quota, they'll be disqualified before they have a chance to answer.
          unknownSatisfies: quotaType === 'all',
        })

        if (quotaType === 'at_most' || quotaType === 'none') {
          return !fitsIntoSegment
        }

        return fitsIntoSegment
      })
    },
  )
  if (!satisfiesAllVariableQuotas) {
    return {
      source: 'glass',
      tactic: 'variable_quota',
    } satisfies DisqualificationReason
  }

  if (!hasSatisfiedQuestionQuotas({ curQuestion })) {
    return {
      source: 'glass',
      tactic: 'question_quota',
    } satisfies DisqualificationReason
  }
}

export function hasSatisfiedQuestionQuotas({
  curQuestion,
}: {
  curQuestion: Question
}) {
  if (
    (curQuestion.type !== 'multipleChoice' && curQuestion.type !== 'ranking') ||
    !curQuestion.constraints.optionQuotas
  ) {
    return true
  }

  const { cantPickOptionIDs, mustPickOptionIDs } =
    curQuestion.constraints.optionQuotas

  let satisfiedMustPick = true
  if (mustPickOptionIDs.length > 0) {
    satisfiedMustPick = mustPickOptionIDs.every((canPickFromOptionIDs) => {
      return Array.from(canPickFromOptionIDs).some((optionID) => {
        return new Set(curQuestion.response).has(`${optionID}`)
      })
    })
  }

  let satisfiedCantPick = true
  if (cantPickOptionIDs.size > 0) {
    satisfiedCantPick = !Array.from(cantPickOptionIDs).some((optionID) => {
      return new Set(curQuestion.response).has(`${optionID}`)
    })
  }

  return satisfiedMustPick && satisfiedCantPick
}
