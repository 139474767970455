import { Dialog as HeadlessUIDialog } from '@headlessui/react'
import { ReactNode } from 'react'

import Button from './Button'
import Icon from './Icon'

const Dialog = ({
  children,
  isOpen,
  onClose,
  title,
}: {
  children: ReactNode
  isOpen: boolean
  onClose(): void
  title: string
}) => {
  return (
    <HeadlessUIDialog
      as="div"
      className="relative z-20"
      onClose={onClose}
      open={isOpen}
    >
      <div className="fixed inset-0 bg-black/25" />

      <div className="fixed inset-0 overflow-y-auto">
        <div className="mb-32 mt-10 flex justify-center p-4">
          <HeadlessUIDialog.Panel className="relative w-full max-w-[1200px] rounded-2xl bg-white p-6 shadow-xl">
            <button
              aria-label="Close Dialog"
              className="absolute right-2 top-2 p-4"
              onClick={onClose}
              type="button"
            >
              <div aria-hidden="true" className="h-6 w-6">
                <Icon id="x-close" />
              </div>
            </button>

            <HeadlessUIDialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {title}
            </HeadlessUIDialog.Title>

            <div className="mt-8">{children}</div>

            <div className="mt-8 text-right">
              <Button onClick={onClose}>Done</Button>
            </div>
          </HeadlessUIDialog.Panel>
        </div>
      </div>
    </HeadlessUIDialog>
  )
}

export default Dialog
