import { DisqualificationReason } from './disqualifications'
import { Question } from './questions'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'

/**
 * Converts a regex quota returned by the API into a text quality match in the application.
 * This is more semantic and easier to work with on our end.
 */
export function buildQualityMatch(question: QuestionAPI) {
  let match = ''

  question.questionQuotas.forEach((quota) => {
    if (quota.logicalModifier === 'all') {
      match = quota.regex ?? ''
    }
  })

  return match
}

/**
 * Returns a DisqualificationReason object if the respondent should be disqualified due
 * to a quality issue with their response to the current question or "undefined" if they
 * should not be disqualified.
 */
export function getQualityDisqualification({
  curQuestion,
}: {
  curQuestion: Question
}) {
  if (curQuestion.type === 'openEnded') {
    const response = standardizeText(curQuestion.response)

    if (curQuestion.quality.match) {
      const standardizedMatch = standardizeText(curQuestion.quality.match)

      if (response !== standardizedMatch) {
        return {
          source: 'glass',
          tactic: 'presurvey_questionnaire',
        } satisfies DisqualificationReason
      }
    }
  }
}

function standardizeText(text: string) {
  return text.toLowerCase().replace(/\s/g, '')
}
