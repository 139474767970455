import { getEnvVar } from 'utils/env'

export type APIVersion = 'v1'

export function getAPIUrl({
  path,
  version,
}: {
  path: string
  version: APIVersion
}) {
  return `${getEnvVar('GLASS_API_HOST')}/api/${version}${path}`
}
