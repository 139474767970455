export interface AnswerGaborGranger {
  id: number
  monadicConceptId?: number
  options: [{ scaleVal: number }]
}

export interface AnswerIdeaPresenter {
  id: number
  monadicConceptId?: number
  options: { conceptId?: number; optionId: number }[]
}

export interface AnswerMatrix {
  id: number
  monadicConceptId?: number
  options:
    | {
        freeTextResponse?: string
        labels: string[]
        optionId: number
      }[]
    | { matrixOptions: { freeText: string; id: number }[]; optionId: number }[]
  optionsSeen?: number[]
  optionsSeenNew?: number[]
}

export interface AnswerMultipleChoice {
  id: number
  monadicConceptId?: number
  options: { freeTextResponse?: string; optionId: number }[]
  optionsSeen?: number[]
  optionsSeenNew?: number[]
}

export interface AnswerOpenEnded {
  freeTextResponse: string
  id: number
  monadicConceptId?: number
  options: never[]
}

export interface AnswerRanking {
  id: number
  monadicConceptId?: number
  options: { optionId: number; rank: number }[]
  optionsSeen?: number[]
  optionsSeenNew?: number[]
}

export interface AnswerScale {
  id: number
  monadicConceptId?: number
  options: { optionId: number; scaleVal: number }[]
  optionsSeen?: number[]
  optionsSeenNew?: number[]
}

export interface AudienceBase<Audience> {
  audience: Audience
  audienceId: number
  categories: {
    attributes: {
      constraints: number[]
      logicalModifier: LogicalModifier
      percentage: number
    }[]
    category: Question
  }[]
  sort: number
  unionModifier: 'and'
}

export interface AudienceBaseAudience {
  audienceSlices: AudienceBaseSlice[]
  creatorId: number
  dependentSlices: boolean
  id: number
  organizationId: number
  title: string
}

export interface AudienceBaseSlice {
  audienceSliceCategories: AudienceBaseSliceCategory[]
  percentage: string
}

export interface AudienceBaseSliceCategory {
  audienceSliceCategoryAttributes:
    | number[]
    | { end: number; start: number }[]
    | { enumValue: number; regex: string }[]
    | { enumValue: number; matrixOptionId: number }[]
    | { enumValue: number; numberRange: { end: number; start: number } }[]
  conceptId: number | null
  logicalModifier: LogicalModifier
  question: Question
  questionId: number
  requiresUpdateRange: boolean
  updateRange: null
}

export interface AudienceConceptAudience extends AudienceBaseAudience {
  parentSurveyId: null
}

export interface AudienceOption extends AudienceBase<AudienceBaseAudience> {
  questionOptionId: number
}

export interface AudienceQuestion
  extends AudienceBase<AudienceQuestionAudience> {
  questionId: number
}

export interface AudienceQuestionBlock
  extends Omit<AudienceBase<AudienceQuestionBlockAudience>, 'categories'> {
  questionBlockId: number
}

export interface AudienceQuestionAudience extends AudienceBaseAudience {
  parentSurveyId: null
}

export interface AudienceQuestionBlockAudience extends AudienceBaseAudience {
  parentSurveyId: null
}

export interface AudienceSlice {
  audienceSliceCategoryId: null
  audienceSliceId: number
  id: number
  isDone: boolean
  numberCompleted: number
  numberNeeded: number
  surveyId: number
  waveId: number
}

export interface AudienceSurvey {
  audienceSlices: AudienceBaseSlice[]
  creatorId: number
  dependentSlices: boolean
  id: number
  organizationId: number | null
  parentSurveyId: null
  title: string
}

export interface Concept {
  audience: AudienceConceptAudience | null
  audienceId: null
  completes: number
  description: string
  id: number
  media: MediaImage | MediaVideo
  preserved: boolean
  questionId: number
  sort: null
  target: null
  targetModifier: null
}

export interface Disqualification {
  questionId: number
  researchDefenderScore: null
  response: string
  surveyId: number
  type: DisqualificationType
  userId: number
}

export type DisqualificationType = 'CHECK' | 'PASTE' | 'QUALITY' | 'QUOTA'

export interface EndMessage {
  subtitle?: string
  title: string
  type: 'disqualified' | 'closed' | 'completed'
}

export type FeatureCode =
  | LabelFeatureCode
  | QuestionBlockFeatureCode
  | QuestionFeatureCode

export interface FeatureWithDescription<
  Code extends FeatureCode = FeatureCode,
> {
  code: Code
  description: string
  id: number
  name: string
}

export type GaborGrangerFormat = 'CUSTOM' | 'DOLLARS' | 'PERCENT'
export type GaborGrangerObjective = 'MAX_SEEKING' | 'MIN_SEEKING'
export interface GaborGrangerSettings {
  format: GaborGrangerFormat
  formatCustomText: string | null
  increment: string
  max: string
  min: string
  objective: GaborGrangerObjective
  unitDecimals: number
}

export interface Label {
  id: number
  isActive: boolean
  isFreeText: boolean
  labelFeatures: LabelFeature<LabelFeatureCode>[]
  optionLabel: string
  weight: null
}

export interface LabelFeature<Code extends LabelFeatureCode> {
  booleanValue: boolean
  enumRegex: null
  enumStringValue: null
  enumValue: null
  feature: FeatureWithDescription<Code>
  featureId: number
  numberValue: null
  questionOptionLabelId: number
  type: 'boolean'
}

export type LabelFeatureCode = 'QOA01' | 'QOE01'

export type LogicalModifier = 'is' | 'isnt' | 'should'

export interface MatrixOption {
  id: number
  isAnchored: boolean
  isExclusive: boolean
  isFreeText: boolean
  sort: number
  title: string
}

export interface MediaImage {
  api_key: string
  asset_id: string
  bytes: number
  created_at: string
  etag: string
  folder: string
  format: string
  height: number
  placeholder: boolean
  public_id: string
  resource_type: 'image'
  secure_url: string
  signature: string
  tags: []
  type: 'upload'
  url: string
  version: number
  version_id: string
  width: number
}

export interface MediaVideo {
  asset_id: string
  audio: {
    bit_rate: string
    channel_layout: string
    channels: number
    codec: string
    frequency: number
  }
  bit_rate: number
  bytes: number
  created_at: string
  duration: number
  etag: string
  format: string
  frame_rate: number
  height: number
  is_audio: boolean
  nb_frames: number
  original_filename: string
  pages: number
  placeholder: boolean
  public_id: string
  resource_type: 'video'
  rotation: number
  secure_url: string
  signature: string
  tags: []
  type: 'upload'
  url: string
  version: number
  version_id: string
  video: {
    bit_rate: string
    codec: string
    dar: string
    level: number
    pix_format: string
    profile: string
    time_base: string
  }
  width: number
}

export interface Option {
  anchored: boolean
  carryOverParentId: number | null
  dataUrl: MediaImage | null
  description: string | null
  exclusive: boolean
  high: number | null
  highLabel: string | null
  highMiddleLabel: null
  id: number
  isFreeTextOption: boolean
  labels: []
  low: number | null
  lowLabel: string | null
  lowMiddleLabel: null
  middleLabel: string | null
  preserved: boolean
  questionOptionAudiences: AudienceOption[]
  questionQuota?: {
    id: number
    isDone: boolean
    logicalModifier: 'all'
    numberCompleted: number
    numberNeeded: number
    questionId: number
    quotaRelationId: null
    type: 'enum'
  }
  scaleTypeId: number | null
  scaleUnitId: number | null
  sort: number
  step: number | null
  title: string
  titleRight: null
  viewRequired: boolean
}

export interface OptionImage extends Option {
  dataUrl: MediaImage
}

export interface OptionScale extends Option {
  high: number
  highLabel: string
  low: number
  lowLabel: string
  scaleTypeId: ScaleType
  scaleUnitId: ScaleUnit
  step: number
}

export interface OptionText extends Option {
  dataUrl: null
}

export interface Question {
  autoSelectOption: boolean
  backgroundColor: null
  blockId: number | null
  body: null
  bucketBlockId: null
  conceptTestMedia: Concept[]
  // Deprecated: conceptTestMedia should be used instead.
  concepts?: never[]
  constraint?: QuestionConstraintNumber | QuestionConstraintRegex
  contentType: { id: number; name: string; description: string }
  contentTypeId: number
  createdAt: string
  csvFileName: null
  csvFileValue: null
  dependentOnId: null
  description: string
  displayOptionDescription: boolean
  // Deprecated: questionFeatures should be used instead.
  features: never[]
  fulcrumQuestionId: number
  fulcrumQuestionName: string
  gaborGrangerSettings: GaborGrangerSettings | null
  hasPriority: boolean
  id: number
  isActive: boolean
  isDemographic: boolean
  isMultipleChoiceMatrix: boolean
  isPublicForAudience: boolean
  isScreener: boolean
  isStandard: boolean
  isTemplate: boolean
  isUniversal: boolean
  label: string | null
  labels?: Label[]
  matrixOptions: MatrixOption[]
  monadicBlockSequences?: number
  monadicId: number | null
  options: Option[]
  questionAudiences: AudienceQuestion[]
  questionComments: []
  questionFeatures: (
    | QuestionFeature<QuestionFeatureCode>
    | QuestionFeatureWithNumberRange<QuestionFeatureCodeWithNumberRange>
  )[]
  questionNumberConstraints: []
  questionQuotas: QuestionQuota[]
  questionStringConstraints: []
  questionType: { id: number; name: string }
  questionTypeId: number
  rdScoreLimit: number
  secondaryBlockId: null
  shouldAskWithoutSurveyAssociation: boolean
  sort: number
  surveyId: number
  templateDescription: null
  templateTitle: null
  title: string
  updatedAt: string
  userId: number
}

export interface QuestionBlock {
  children: QuestionBlockChild[]
  countInX: boolean
  hasPriority: boolean
  id: number
  isBucket: boolean
  isMasterBlock: boolean
  isMonadic: boolean
  isRandomized: boolean
  parentId: null
  questionBlockAudiences: never[]
  questionBlockFeatures: (
    | QuestionBlockFeature<QuestionBlockFeatureCode>
    | QuestionBlockFeatureWithNumberRange<QuestionBlockFeatureCodeWithNumberRange>
  )[]
  sequences: null
  sort: null
  surveyId: number
  title: string
}

export interface QuestionBlockChild {
  countInX: boolean
  hasPriority: boolean
  id: number
  isBucket: boolean
  isMasterBlock: boolean
  isMonadic: boolean
  isRandomized: boolean
  parentId: number
  questionBlockAudiences: AudienceQuestionBlock[]
  questions: Question[]
  secondaryBlocks: []
  sequences: null
  sort: number
  surveyId: number
  title: string
}

export type QuestionBlockFeature<Code extends QuestionBlockFeatureCode> = {
  booleanValue: null
  enumRegex: null
  enumValue: null
  feature: FeatureWithDescription<Code>
  featureId: number
  questionBlockId: number
  stringValue: null
  type: 'number'
}

export type QuestionBlockFeatureCodeBase = ''
export type QuestionBlockFeatureCodeWithNumberRange = 'XOY02'
export type QuestionBlockFeatureCode =
  | QuestionBlockFeatureCodeBase
  | QuestionBlockFeatureCodeWithNumberRange

export interface QuestionBlockFeatureWithNumberRange<
  Code extends QuestionBlockFeatureCodeWithNumberRange,
> extends QuestionBlockFeature<Code> {
  numberRange: { end: number; start: number }
}

export interface QuestionConstraintNumber {
  id: number
  questionId: number
  range: { end: number; start: number }
}

export interface QuestionConstraintRegex {
  errorMessage: string
  id: number
  questionId: number
  regex: string
}

export type QuestionFeature<Code extends QuestionFeatureCode> = {
  booleanValue: boolean
  enumRegex: string | null
  enumValue: number | null
  feature: FeatureWithDescription<Code>
  featureId: number
  matrixOptionId: number | null
  questionId: number
  regex: string | null
  type: 'boolean'
}

export type QuestionFeatureBase = QuestionFeature<QuestionFeatureCodeBase>

export interface QuestionFeatureWithNumberRange<
  Code extends QuestionFeatureCodeWithNumberRange,
> extends QuestionFeature<Code> {
  numberRange: { end: number; start: number }
}

export interface QuestionFeatureWithRegex<
  Code extends QuestionFeatureCodeWithRegex,
> extends QuestionFeature<Code> {
  regex: string
}

export type QuestionFeatureCodeBase =
  | 'COD01'
  | 'CON01'
  | 'COS01'
  | 'MRS01'
  | 'OM01'
  | 'OMI01'
  | 'OMS01'
  | 'QMCM01'
  | 'RDM01'
  | 'RDM02'
  | 'RMO'
  | 'SRS01'
  | 'VAL01'
  | 'VAL02'
  | 'VoxpopmeFeature02'
export type QuestionFeatureCodeWithNumberRange =
  | 'MCLL01'
  | 'MCUL01'
  | 'OPBLCK01'
  | 'QPC01'
  | 'SUM01'
export type QuestionFeatureCodeWithRegex = 'VoxpopmeFeature01'
export type QuestionFeatureCode =
  | QuestionFeatureCodeBase
  | QuestionFeatureCodeWithNumberRange
  | QuestionFeatureCodeWithRegex

export interface QuestionQuota {
  booleanValue: null
  id: number
  // @deprecated numberCompleted and numberNeeded should be used instead.
  isDone: boolean
  logicalModifier: 'all' | 'at_least' | 'at_most' | 'none'
  numberCompleted: number
  numberNeeded: number
  previousWaveParentId: null
  questionId: number
  questionOptions?: Option[]
  quotaRelationId: null
  regex: string | null
  type: 'enum' | 'string'
  waveId: number
}

export const SCALE_TYPES = {
  RADIO: 1,
  SLIDER: 2,
} as const
export type ScaleType = (typeof SCALE_TYPES)[keyof typeof SCALE_TYPES]

export const SCALE_UNITS = {
  DOLLARS: 2,
  NONE: 1,
  PERCENTAGES: 3,
} as const
export type ScaleUnit = (typeof SCALE_UNITS)[keyof typeof SCALE_UNITS]

export interface Survey {
  accountNotNeeded: boolean
  audience: AudienceSurvey
  audienceId: number
  audienceSlices: AudienceSlice[]
  completionRedirectUrl: null
  createdAt: string
  customizations: {
    buttonText: SurveyButtonText | null
    endMessages: EndMessage[]
  }
  description: null
  endAt: string
  estimatedBalance: string
  flaggedForReconcile: boolean
  handleRedirects: boolean
  hash: string
  id: number
  image: null
  imageBackground: null
  incidenceTypeId: number
  isAdminProtected: boolean
  isBringYourOwnAudience: boolean
  isTemplate: boolean
  isUniversal: boolean
  launchAt: string
  numberOfCompletes: number
  organizationId: number
  paidFor: boolean
  parentId: null
  participants: number
  projectId: null
  qualityChecks: SurveyQualityCheck[]
  questionBlocks: QuestionBlock[]
  questions: Question[]
  requireMainProfile: boolean
  requiresAnswerValidation: boolean
  skipIdx: null
  status: { id: number; name: 'approved' | 'completed' | 'draft' }
  statusId: number
  subTitle: null
  surveyLengthId: number
  surveyVariables: SurveyVariable[]
  title: string
  updatedAt: string
  useAgeCensus: boolean
  useEthnicityCensus: boolean
  useGenderCensus: boolean
  useImage: boolean
  useNewMatrixOptions: boolean
  useRaceCensus: boolean
  useRegionCensus: boolean
  useScreener: boolean
  userId: number
  waveId: number
  whiteLabeled: boolean
}

export interface SurveyButtonText {
  advance?: string
  done?: string
}

export interface SurveyQualityCheck {
  enabled: boolean
  type:
    | 'bad_respondent'
    | 'copy_paste'
    | 'duplicate'
    | 'presurvey_questionnaire'
}

export interface SurveyVariable {
  createdAt: string
  creatorId: number
  id: number
  quotas: SurveyVariableQuota[]
  segments: SurveyVariableSegment[]
  surveyId: number
  title: string
  updatedAt: string
  waveId: number
}

export interface SurveyVariableQuota {
  createdAt: string
  creatorId: number
  id: number
  numberCompleted: number
  numberNeeded: number
  previousWaveParentId: null
  type: 'all' | 'at_most' | 'none'
  updatedAt: string
  variableId: number
  waveId: number
}

export interface SurveyVariableSegment {
  createdAt: string
  id: number
  isUserSpecified: boolean
  questions: SurveyVariableSegmentQuestion[]
  quotaId: number
  sort: number
  surveyVariableId: number
  title: string
  updatedAt: string
}

export interface SurveyVariableSegmentQuestion {
  andGrouping: number
  constraints: SurveyVariableSegmentQuestionConstraint[]
  id: number
  logicalModifier: 'is' | 'isnt' | 'should'
  questionId: number
  surveyVariableSegmentId: number
  useDaterange: boolean
}

export interface SurveyVariableSegmentQuestionConstraint {
  conceptId: null
  id: number
  matrixOptions: string[] | null
  matrixOptionIds: number[] | null
  numberRange?: { end: number; start: number }
  optionId: number | null
  surveyVariableSegmentQuestionId: number
}

export interface SurveyVariableSegmentQuestionConstraintMatrix
  extends SurveyVariableSegmentQuestionConstraint {
  matrixOptions: string[]
}

export interface SurveyVariableSegmentQuestionConstraintMatrixOptionIds
  extends SurveyVariableSegmentQuestionConstraint {
  matrixOptionIds: number[]
}

export interface SurveyVariableSegmentQuestionConstraintNumberRange
  extends SurveyVariableSegmentQuestionConstraint {
  numberRange: { end: number; start: number }
}

export interface SurveyVariableSegmentQuestionConstraintNumberRangeWithOptionID
  extends SurveyVariableSegmentQuestionConstraintNumberRange {
  optionId: number
}

export interface User {
  email: null
  id: number
  isTempUser: boolean
  lastCompletedSurvey: string
  lastStreakUpdated: string
  lucidRid: null
  mobile: null
  name: { first: null; last: null }
  notification: { smsAlert: boolean; emailAlert: boolean; pn: boolean }
  points: number
  referralCode: null
  reward: string
  rewardValue: string
  source: null
  streak: number
  userAttributes: []
}
