import {
  compact,
  difference,
  every,
  isEqual,
  mapValues,
  size,
  some,
} from 'lodash-es'

import {
  AudienceBaseSliceCategory,
  LogicalModifier,
  SurveyVariableSegmentQuestionConstraint,
  SurveyVariableSegmentQuestionConstraintMatrix,
  SurveyVariableSegmentQuestionConstraintMatrixOptionIds,
  SurveyVariableSegmentQuestionConstraintNumberRange,
  SurveyVariableSegmentQuestionConstraintNumberRangeWithOptionID,
} from 'types/glass-api/domainModels'
import {
  BlockSetMonadic,
  getMonadicBlockIterationForConcept,
  getMonadicViewedConceptIDs,
} from './questionBlocks'
import {
  getAllPreviousQuestions,
  getDisplayError,
  getPreviousQuestionForIteration,
  Question,
} from './questions'

export type ConditionItemBlockSetMonadic = {
  id: string
  type: 'blockSetMonadic'
}
export type ConditionItemQuestion = {
  conceptId: string | null
  id: string
  type: 'question'
}
export type Condition = {
  expectedResponse:
    | { type: 'nestedIDs'; value: Record<string, Set<string>> }
    | { type: 'range'; value: [number, number][] }
    // A resource ID for the rangeByID type could be an array if multiple ranges
    // are configured for the resource ID. For example, you could configure a
    // condition for a scale that the value must be between 1 and 3 or between
    // 5 and 7.
    | { type: 'rangeByID'; value: Record<string, [number, number][]> }
    | { type: 'setOfIDs'; value: Set<string> }
    | { type: 'titlesByID'; value: Record<string, string[]> }
  item: ConditionItemBlockSetMonadic | ConditionItemQuestion
  logic: LogicalModifier
}

export type ConditionGroups = {
  groupLogic: 'and' | 'or'
  groups: Condition[][]
}

/**
 * Converts the API audienceSliceCategoryAttributes array into a format that's
 * easier to work with when checking for expected responses to a question.
 */
export function buildExpectedResponseFromAPIAudienceAttributes(
  attrs: AudienceBaseSliceCategory['audienceSliceCategoryAttributes'],
): Condition['expectedResponse'] {
  if (isNumberAttributes(attrs)) {
    return {
      type: 'setOfIDs' as const,
      value: new Set(attrs.map((id) => `${id}`)),
    }
  } else if (isRangeAttributes(attrs)) {
    return {
      type: 'range' as const,
      value: attrs.map(({ end, start }) => {
        return [start, end] satisfies [number, number]
      }),
    }
  } else if (isNumberRangeAttributes(attrs)) {
    const optionIDsToRange: Record<string, [number, number][]> = {}

    attrs.forEach(({ enumValue, numberRange }) => {
      const existingRanges = optionIDsToRange[`${enumValue}`]
      const range: [number, number] = [numberRange.start, numberRange.end]

      if (existingRanges) {
        existingRanges.push(range)
      } else {
        optionIDsToRange[`${enumValue}`] = [range]
      }
    })

    return { type: 'rangeByID' as const, value: optionIDsToRange }
  } else if (isMatrixOptionIdAttributes(attrs)) {
    const nestedIDs: Record<string, Set<string>> = {}

    attrs.forEach(({ enumValue, matrixOptionId }) => {
      const existingIDs = nestedIDs[`${enumValue}`]
      if (existingIDs) {
        existingIDs.add(`${matrixOptionId}`)
      } else {
        nestedIDs[`${enumValue}`] = new Set([`${matrixOptionId}`])
      }
    })

    return { type: 'nestedIDs' as const, value: nestedIDs }
  }

  const titlesByID: Record<string, string[]> = {}

  attrs.forEach(({ enumValue, regex }) => {
    const existingTitles = titlesByID[`${enumValue}`]
    if (existingTitles) {
      existingTitles.push(regex)
    } else {
      titlesByID[`${enumValue}`] = [regex]
    }
  })

  return { type: 'titlesByID' as const, value: titlesByID }
}

/**
 * Converts the API segment constraints array for survey variable quotas into a format that's
 * easier to work with when checking for expected responses to a question.
 */
export function buildExpectedResponseFromSegmentQuestionConstraints(
  constraints: SurveyVariableSegmentQuestionConstraint[],
): Condition['expectedResponse'] {
  if (isNumberRangeConstraintsWithOptionID(constraints)) {
    const optionIDsToRange: Record<string, [number, number][]> = {}

    constraints.forEach(({ numberRange, optionId }) => {
      const existingRanges = optionIDsToRange[`${optionId}`]
      const range: [number, number] = [numberRange.start, numberRange.end]

      if (existingRanges) {
        existingRanges.push(range)
      } else {
        optionIDsToRange[`${optionId}`] = [range]
      }
    })

    return { type: 'rangeByID' as const, value: optionIDsToRange }
  } else if (isNumberRangeConstraints(constraints)) {
    return {
      type: 'range' as const,
      value: constraints.map(({ numberRange }) => {
        return [numberRange.start, numberRange.end] satisfies [number, number]
      }),
    }
  } else if (isMatrixOptionIdsConstraints(constraints)) {
    const nestedIDs: Record<string, Set<string>> = {}

    constraints.forEach(({ matrixOptionIds, optionId }) => {
      nestedIDs[`${optionId}`] = new Set(matrixOptionIds.map((id) => `${id}`))
    })

    return { type: 'nestedIDs' as const, value: nestedIDs }
  } else if (isMatrixOptionsConstraints(constraints)) {
    const titlesByID: Record<string, string[]> = {}

    constraints.forEach(({ matrixOptions, optionId }) => {
      titlesByID[`${optionId}`] = matrixOptions
    })

    return { type: 'titlesByID' as const, value: titlesByID }
  }

  return {
    type: 'setOfIDs' as const,
    value: new Set(constraints.map(({ optionId }) => `${optionId}`)),
  }
}

/**
 * Returns a boolean indicating if the response to the previous question
 * satisfied the expected response.
 *
 * @param unknownSatisfies What to return if the previous question does not yet have a response
 *  or there's a mismatch between the question type and the expected response type.
 */
export function didSatisfyCondition({
  curIteration,
  expectedResponse,
  logic,
  monadicBlocks,
  previousItem,
  questions,
  unknownSatisfies = true,
}: Pick<Condition, 'expectedResponse' | 'logic'> & {
  curIteration?: string
  previousItem?:
    | { data: BlockSetMonadic; type: 'blockSetMonadic' }
    | { data: Question[]; type: 'question' }
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  unknownSatisfies?: boolean
}) {
  if (!previousItem) {
    return false
  }

  if (previousItem.type === 'blockSetMonadic') {
    const seenConceptIDs = getMonadicViewedConceptIDs({
      blockSetMonadic: previousItem.data,
      iteration: curIteration ? { id: curIteration, logic: 'eq' } : undefined,
      questions,
    })

    if (
      // This is really strange of our API, but it returns conditions for concepts
      // as a number range constraint.
      expectedResponse.type === 'rangeByID' &&
      seenConceptIDs.size > 0
    ) {
      return satisfiesSeenConcepts({
        expectedResponse: expectedResponse.value,
        logic: logic === 'is' ? 'should' : logic,
        seenConceptIDs,
      })
    }

    return false
  }

  return previousItem.data.some((previousQuestion) => {
    // If we couldn't display the previous question, then the condition couldn't have been
    // satisfied since the respondent never had a chance to respond.
    if (
      getDisplayError({ monadicBlocks, question: previousQuestion, questions })
    ) {
      return false
    }

    if (
      previousQuestion.type === 'ideaPresenter' &&
      // This is really strange of our API, but it returns conditions for concepts
      // as a number range constraint. The only display logic we support for concepts
      // is for idea presenter questions.
      expectedResponse.type === 'rangeByID' &&
      previousQuestion.concepts.length > 0
    ) {
      return satisfiesSeenConcepts({
        expectedResponse: expectedResponse.value,
        logic,
        seenConceptIDs: new Set([previousQuestion.concepts[0].id]),
      })
    } else if (
      previousQuestion.type === 'matrix' &&
      size(previousQuestion.response) > 0
    ) {
      if (expectedResponse.type === 'titlesByID') {
        const expectedStatmentIDsToOptionIDs = mapValues(
          expectedResponse.value,
          (options) => {
            const optionsByTitles = compact(
              options.map((option) => {
                return previousQuestion.options.find(
                  ({ title }) => title === option,
                )
              }),
            )

            return new Set(optionsByTitles.map(({ id }) => id))
          },
        )

        return satisfiesSetMapLogic({
          actualResponse: previousQuestion.response,
          expectedResponse: expectedStatmentIDsToOptionIDs,
          logic,
        })
      } else if (expectedResponse.type === 'nestedIDs') {
        return satisfiesSetMapLogic({
          actualResponse: previousQuestion.response,
          expectedResponse: expectedResponse.value,
          logic,
        })
      }
    } else if (
      previousQuestion.type === 'multipleChoice' &&
      expectedResponse.type === 'setOfIDs' &&
      previousQuestion.response.size > 0
    ) {
      return satisfiesSetLogic({
        actualResponse: previousQuestion.response,
        expectedResponse: expectedResponse.value,
        // Typically, "is" logic means that the user must have selected the exact
        // expected responses. However, if the question is multiple response, we
        // allow the user to select other options in addition to the one required.
        logic:
          logic === 'is' && !previousQuestion.constraints.singleChoice
            ? 'should'
            : logic,
      })
    } else if (
      previousQuestion.type === 'openEnded' &&
      previousQuestion.responseConfig.type === 'number' &&
      expectedResponse.type === 'range' &&
      previousQuestion.response
    ) {
      return satisfiesRangeLogic({
        actualResponse: Number(previousQuestion.response),
        expectedResponse: expectedResponse.value,
        logic,
      })
    } else if (
      previousQuestion.type === 'ranking' &&
      expectedResponse.type === 'rangeByID' &&
      previousQuestion.response.length > 0
    ) {
      const rankByOptionID: Record<string, number> = {}
      previousQuestion.response.forEach((optionID, i) => {
        // The rank the respondent gives is 1-based but our array is 0-based.
        // We convert to 1-based to compare to an expected range configured in
        // display logic, which is also 1-based.
        rankByOptionID[optionID] = i + 1
      })

      return satisfiesRangeMapLogic({
        actualResponse: rankByOptionID,
        expectedResponse: expectedResponse.value,
        logic,
      })
    } else if (
      (previousQuestion.type === 'bipolar' ||
        previousQuestion.type === 'scale') &&
      expectedResponse.type === 'rangeByID' &&
      size(previousQuestion.response) > 0
    ) {
      return satisfiesRangeMapLogic({
        actualResponse: previousQuestion.response,
        expectedResponse: expectedResponse.value,
        logic,
      })
    }

    // If we got to this point, the most likely scenario is that the respondent hasn't
    // yet seen or responded to the previous question. The purpose of this function is to
    // essentially return false if they absolutely did not satisfy the response, so we assume
    // they will (or could) once they see / respond to the question. In other words, we give
    // them the benefit of the doubt.
    return unknownSatisfies
  })
}

/**
 * Checks if the provided condition groups are satisfied based on the responses to previous
 * questions.
 *
 * If a current iteration is supplied, the previous question for the current iteration will
 * be used. For example, if the current iteration is 0 and a condition references question
 * 10, we'll look for question 10-0 in the previous questions.
 */
export function didSatisfyConditionGroups({
  conditionGroups,
  curIteration,
  monadicBlocks,
  questions,
  unknownSatisfies = true,
}: {
  conditionGroups: ConditionGroups
  curIteration?: string
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  unknownSatisfies?: boolean
}) {
  // If the top-level group logic is AND, then the logic within a group is OR.
  // This represents the case (A OR B) AND (C OR D).
  if (conditionGroups.groupLogic === 'and') {
    return conditionGroups.groups.every((group) =>
      group.some(({ item, ...rest }) => {
        return didSatisfyCondition({
          ...rest,
          curIteration,
          monadicBlocks,
          previousItem: getPreviousItem({
            item,
            iteration: curIteration,
            monadicBlocks,
            questions,
          }),
          questions,
          unknownSatisfies,
        })
      }),
    )
  }

  // In this case, the top-level group logic is OR, so the logic within a group is AND.
  // This represents the case (A AND B) OR (C AND D).
  return conditionGroups.groups.some((group) =>
    group.every(({ item, ...rest }) => {
      return didSatisfyCondition({
        ...rest,
        curIteration,
        monadicBlocks,
        previousItem: getPreviousItem({
          item,
          iteration: curIteration,
          monadicBlocks,
          questions,
        }),
        questions,
        unknownSatisfies,
      })
    }),
  )
}

export function getConceptIDsFromExpectedResponse(
  expectedResponse: Record<string, [number, number][]>,
) {
  // The expectedResponse here is of the form: { '1': [1, 1], '2': [2, 2] }
  // where the keys are not meaningful in this context and the range is
  // always the same number and represents the concept IDs. So we just
  // grab those concept IDs as the expected response.
  return Object.values(expectedResponse).flatMap((ranges) =>
    ranges.map((range) => `${range[0]}`),
  )
}

/**
 * Accepts a condition item, which references a previous monadic block ID or question ID, and
 * returns an object with a "data" property populated with the corresponding monadic block or
 * question.
 */
export function getPreviousItem({
  item,
  iteration,
  monadicBlocks,
  questions,
}: {
  item: Condition['item']
  iteration?: string
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
}) {
  // A previous item could be a monadic block set if display logic is configured for
  // the concept seen for the monadic loop.
  if (item.type === 'blockSetMonadic') {
    return monadicBlocks[item.id]
      ? { data: monadicBlocks[item.id], type: item.type }
      : undefined
  }
  // If the item to get specifies a concept ID, then we want to get the previous item
  // that was shown when the specified concept as also shown. For example, display logic
  // could be configured like:
  // "Only show this when the respondent saw concept 1 and chose option 2 for Q2 inside the loop".
  else if (item.conceptId) {
    const iterationForConcept = getMonadicBlockIterationForConcept({
      conceptID: item.conceptId,
      monadicBlocks,
      questions,
    })

    if (
      iterationForConcept !== null &&
      (!iteration || Number(iteration) === Number(iterationForConcept))
    ) {
      const previousQuestion = getPreviousQuestionForIteration({
        iteration: iterationForConcept,
        previousQuestionID: item.id,
        questions,
      })

      return previousQuestion
        ? { data: [previousQuestion], type: item.type }
        : undefined
    }
  }
  // At this point we're not looking for a monadic block or a question that was
  // shown when a specific concept was shown. We could be looking for a single question
  // or all question responses for a monadic loop (e.g. respondent answered Option 2 for Q2
  // for any iteration of the monadic block).
  else {
    if (iteration) {
      const previousQuestion = getPreviousQuestionForIteration({
        iteration,
        previousQuestionID: item.id,
        questions,
      })

      return previousQuestion
        ? { data: [previousQuestion], type: item.type }
        : undefined
    }

    const previousQuestions = getAllPreviousQuestions({
      previousQuestionID: item.id,
      questions,
    })

    return { data: previousQuestions, type: item.type }
  }
}

function isNumberAttributes(
  attributes: AudienceBaseSliceCategory['audienceSliceCategoryAttributes'],
): attributes is number[] {
  return typeof attributes[0] === 'number'
}

function isNumberRangeAttributes(
  attributes: AudienceBaseSliceCategory['audienceSliceCategoryAttributes'],
): attributes is {
  enumValue: number
  numberRange: { end: number; start: number }
}[] {
  return !isNumberAttributes(attributes) && 'numberRange' in attributes[0]
}

function isRangeAttributes(
  attributes: AudienceBaseSliceCategory['audienceSliceCategoryAttributes'],
): attributes is { end: number; start: number }[] {
  return !isNumberAttributes(attributes) && 'end' in attributes[0]
}

function isMatrixOptionIdAttributes(
  attributes: AudienceBaseSliceCategory['audienceSliceCategoryAttributes'],
): attributes is { enumValue: number; matrixOptionId: number }[] {
  return !isNumberAttributes(attributes) && 'matrixOptionId' in attributes[0]
}

function isMatrixOptionIdsConstraints(
  constraints: SurveyVariableSegmentQuestionConstraint[],
): constraints is SurveyVariableSegmentQuestionConstraintMatrixOptionIds[] {
  return constraints[0].matrixOptionIds !== null
}

function isMatrixOptionsConstraints(
  constraints: SurveyVariableSegmentQuestionConstraint[],
): constraints is SurveyVariableSegmentQuestionConstraintMatrix[] {
  return constraints[0].matrixOptions !== null
}

function isNumberRangeConstraints(
  constraints: SurveyVariableSegmentQuestionConstraint[],
): constraints is SurveyVariableSegmentQuestionConstraintNumberRange[] {
  return 'numberRange' in constraints[0] && !!constraints[0].numberRange
}

function isNumberRangeConstraintsWithOptionID(
  constraints: SurveyVariableSegmentQuestionConstraint[],
): constraints is SurveyVariableSegmentQuestionConstraintNumberRangeWithOptionID[] {
  return isNumberRangeConstraints(constraints) && !!constraints[0].optionId
}

/**
 * Checks the provided actual and expected responses for:
 *
 * - is: The actual response is within all of the expected ranges.
 * - should: The actual response is within at least one of the expected ranges.
 * - isnt: The actual response is not within any of the expected ranges.
 */
export function satisfiesRangeLogic({
  actualResponse,
  expectedResponse,
  logic,
}: {
  actualResponse: number
  expectedResponse: [number, number][]
  logic: LogicalModifier
}) {
  if (logic === 'is') {
    return expectedResponse.every((range) => {
      return actualResponse >= range[0] && actualResponse <= range[1]
    })
  }

  const someValueInRange = expectedResponse.some(
    (range) => actualResponse >= range[0] && actualResponse <= range[1],
  )

  return logic === 'should' ? someValueInRange : !someValueInRange
}

/**
 * Checks the provided actual and expected responses for:
 *
 * - is: All of the entries in the actual response are within the expected range. All of
 *      the keys in the expected response must be in the actual response.
 * - should: At least one of the entries in the actual response are within the expected range.
 * - isnt: None of the entries in the actual response are within the expected range. A missing
 *      key counts as not being within the expected range.
 */
export function satisfiesRangeMapLogic({
  actualResponse,
  expectedResponse,
  logic,
}: {
  actualResponse: Record<string, number>
  expectedResponse: Record<string, [number, number][]>
  logic: LogicalModifier
}) {
  if (logic === 'is') {
    return every(expectedResponse, (ranges, optionID) => {
      const valueForOptionID = actualResponse[Number(optionID)]
      if (!valueForOptionID) {
        return false
      }

      return satisfiesRangeLogic({
        actualResponse: valueForOptionID,
        expectedResponse: ranges,
        logic,
      })
    })
  }

  const someValueInRange = some(expectedResponse, (ranges, optionID) => {
    const valueForOptionID = actualResponse[Number(optionID)]
    if (!valueForOptionID) {
      return false
    }

    return ranges.some(
      (range) => valueForOptionID >= range[0] && valueForOptionID <= range[1],
    )
  })

  return logic === 'should' ? someValueInRange : !someValueInRange
}

/**
 * Checks that the provided seenConceptIDs set satisfies the expected response
 * according to the logic.
 */
function satisfiesSeenConcepts({
  expectedResponse,
  logic,
  seenConceptIDs,
}: {
  expectedResponse: Record<string, [number, number][]>
  logic: LogicalModifier
  seenConceptIDs: Set<string>
}) {
  return satisfiesSetLogic({
    actualResponse: seenConceptIDs,
    expectedResponse: new Set(
      getConceptIDsFromExpectedResponse(expectedResponse),
    ),
    logic,
  })
}

/**
 * Checks the provided actual and expected responses for:
 *
 * - is: The sets must be an exact match.
 * - should: The actual response must contain at least one of the expected entries.
 * - isnt: The actual response must not contain any of the expected entries.
 */
export function satisfiesSetLogic({
  actualResponse,
  expectedResponse,
  logic,
}: {
  actualResponse: Set<string>
  expectedResponse: Set<string>
  logic: LogicalModifier
}) {
  if (logic === 'is') {
    return isEqual(actualResponse, expectedResponse)
  }

  const choseAnOption = Array.from(expectedResponse).some((id) =>
    actualResponse.has(id),
  )

  return logic === 'should' ? choseAnOption : !choseAnOption
}

/**
 * Checks the provided actual and expected responses for:
 *
 * - is: All of the entries in the expected response must have the exact entry in the actual
 *      response set.
 * - should: At least one of the entries in the actual response has a value from the expected
 *      response set. Note: the sets don't have to match exactly.
 * - isnt: None of the entries in the actual response have a value from the expected set.
 *      A missing key counts as not having a value from the expected set.
 */
export function satisfiesSetMapLogic({
  actualResponse,
  expectedResponse,
  logic,
}: {
  actualResponse: Record<string, Set<string>>
  expectedResponse: Record<string, Set<string>>
  logic: LogicalModifier
}) {
  if (logic === 'is') {
    return every(expectedResponse, (ids, optionID) => {
      return (
        actualResponse[Number(optionID)] &&
        // The actual response can contain more options than the expected response.
        // We just want to make sure the actual response is a superset. For example, {1, 2, 3}
        // is a superset of {1, 2}.
        difference(
          Array.from(ids),
          Array.from(actualResponse[Number(optionID)]),
        ).length === 0
      )
    })
  }

  const someEntryMatches = some(expectedResponse, (ids, optionID) => {
    const responseForOption = actualResponse[Number(optionID)]
    if (!responseForOption) {
      return false
    }

    return Array.from(ids).some((id) => responseForOption.has(id))
  })

  return logic === 'should' ? someEntryMatches : !someEntryMatches
}
