import { Question as QuestionMultipleChoice } from './multipleChoice'
import { Question as QuestionRanking } from './ranking'

/**
 * Returns an error message if the user has not selected the minimum number of options
 * or has selected more than the allowed maximum.
 */
export function getMinMaxError({
  curResponse,
  question,
}: {
  curResponse: Set<string>
  question: QuestionMultipleChoice | QuestionRanking
}) {
  if (!question.constraints.max && !question.constraints.min) {
    return ''
  }

  const { max, min } = question.constraints
  const numSelections = curResponse.size

  if (min && numSelections < min) {
    return `Please select at least ${min} options.`
  }

  if (max && numSelections > max) {
    return `Please select at most ${max} options.`
  }

  return ''
}
