import { forwardRef, InputHTMLAttributes } from 'react'

const Input = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'placeholder'>
>(function Input(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      className="text-gray-2 w-full rounded border border-gray-4 p-3 placeholder:text-gray-3"
      placeholder="Begin typing here..."
    />
  )
})

export default Input
