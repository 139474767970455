import {
  ClipboardEvent,
  ComponentProps,
  forwardRef,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  canContinue,
  isResponseDisabled,
  Question as QuestionType,
} from 'utils/openEnded'
import { getStepForInputType } from 'utils/forms'
import { useConcept } from 'hooks/concepts'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import FieldError from './FieldError'
import Input from './Input'
import Question from './Question'

interface OpenEndedForm {
  response: string
}

const OpenEndedQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onPaste,
  onViewedConcept,
  question,
  userID,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion: SubmitHandler<OpenEndedForm>
  onPaste(freeText: string): void
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
  userID: number | null
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<OpenEndedForm>({
    defaultValues: {
      response: question.response,
    },
  })

  const curResponse = watch('response')

  const responseFields = register('response', {
    disabled: isResponseDisabled({ question }),
    max: question.constraints.max,
    min: question.constraints.min,
    pattern: question.constraints.textRegExp,
    required: true,
  })

  function onPasteInternal(
    event: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    onPaste(event.clipboardData.getData('text'))
  }

  const { concept } = useConcept({ onViewedConcept, question })

  const onFinishedVideo = useCallback(() => {
    setValue('response', 'Recorded successfully')
  }, [setValue])
  const onToggledSkipVideo = useCallback(
    (skipped: boolean) => {
      setValue('response', skipped ? 'Requested skip' : '')
    },
    [setValue],
  )

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onCompletedQuestion)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        {question.responseConfig.type === 'audio-video' ? (
          <VideoResponse
            allowSkip={!question.responseConfig.responseRequired}
            onFinishedVideo={onFinishedVideo}
            onToggledSkipVideo={onToggledSkipVideo}
            projectID={question.responseConfig.projectID}
            questionID={question.id}
            userID={userID}
          />
        ) : (
          <div className="space-y-2">
            {question.responseConfig.type === 'text' ? (
              <OpenEndedTextarea
                {...responseFields}
                onPaste={onPasteInternal}
              />
            ) : (
              <Input
                {...responseFields}
                inputMode={
                  question.responseConfig.type === 'number'
                    ? 'decimal'
                    : undefined
                }
                onPaste={onPasteInternal}
                step={getStepForInputType(question.responseConfig.type)}
                type={question.responseConfig.type}
              />
            )}

            {errors.response?.message && (
              <FieldError>{errors.response.message}</FieldError>
            )}
          </div>
        )}
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ curResponse, question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default OpenEndedQuestion

export const OpenEndedTextarea = forwardRef<
  HTMLTextAreaElement,
  Omit<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    'className' | 'placeholder' | 'rows'
  >
>(function OpenEndedTextbox(props, ref) {
  return (
    <textarea
      {...props}
      ref={ref}
      className="text-gray-2 w-full rounded border border-gray-4 p-3 placeholder:text-gray-3"
      placeholder="Begin typing here..."
      rows={4}
    />
  )
})

const VideoResponse = ({
  allowSkip,
  onFinishedVideo,
  onToggledSkipVideo,
  projectID,
  questionID,
  userID,
}: {
  allowSkip: boolean
  onFinishedVideo(): void
  onToggledSkipVideo(skipped: boolean): void
  projectID: string
  questionID: string
  userID: number | null
}) => {
  // In strict mode, React will call our useEffect twice. We only want to insert the Voxpopme script
  // once though so we keep track of whether or not we've done that here.
  const insertedScript = useRef(false)

  useEffect(() => {
    if (insertedScript.current) {
      return
    }

    insertedScript.current = true

    // @ts-expect-error This is the global configuration object expected by Voxpopme.
    window.vpm_widget_config = {
      additional_data: { questionID, respondentID: userID },
      disable_next_button() {
        // We actually don't need to do anything in this function since our "Next" survey
        // button is disabled until there's a response in our system for the question.
      },
      enable_next_button() {
        // This hardcoded string is so we can capture an answer for the respondent when
        // we store their survey answers in our system.
        onFinishedVideo()
      },
      project_id: projectID,
      settings: { locale: 'en_US' },
    }

    const voxpopmeScript = document.createElement('script')
    voxpopmeScript.type = 'text/javascript'
    voxpopmeScript.src = 'https://capture.voxpopme.net/main.js'

    const firstScript = document.getElementsByTagName('script')[0]
    firstScript?.parentNode?.insertBefore(voxpopmeScript, firstScript)

    return () => {
      // Unfortunately the only way to recreate the Voxpopme widget is to remove
      // their JavaScript and re-add to the DOM. We asked their support if there's
      // an object on the window we can interact with and they said they don't
      // support that. This is hacky but if it doesn't find any scripts, it doesn't
      // break the functionality. Removing and re-adding is useful in preview mode
      // for the survey when you can go back and forth between questions.
      const voxpopmeScripts = document.querySelectorAll(
        'script[src^="https://capture.voxpopme.net"]',
      )

      voxpopmeScripts.forEach((script) => {
        script.remove()
      })
    }
  }, [onFinishedVideo, projectID, questionID, userID])

  return (
    <div>
      <div
        className="vpm-capture-widget flex justify-center"
        data-testid="video-response-container"
      />

      {allowSkip && (
        <div className="mt-2 flex justify-center">
          <label className="flex items-start space-x-2 text-sm">
            <input
              className="mt-1"
              onChange={(event) => {
                onToggledSkipVideo(event.target.checked)
              }}
              type="checkbox"
            />
            <span>
              I'm having technical difficulties or would no longer like to
              record
            </span>
          </label>
        </div>
      )}
    </div>
  )
}
