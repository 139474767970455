import { useMutation, useQuery } from '@tanstack/react-query'

import {
  getSurvey,
  submitAnswers,
  trackDisqualification,
} from 'services/glass-api/surveys'
import { minutesToMs } from 'utils/general'

export function useSubmitAnswers({ retry }: { retry: number }) {
  return useMutation({ mutationFn: submitAnswers, retry })
}

export function useSurvey({ surveyHash }: { surveyHash: string | undefined }) {
  return useQuery({
    enabled: !!surveyHash,
    queryFn: () => {
      if (!surveyHash) {
        throw new Error('No survey hash provided')
      }

      return getSurvey({ surveyHash })
    },
    queryKey: ['surveys', surveyHash],
    staleTime: minutesToMs(5),
  })
}

export function useTrackDisqualification() {
  return useMutation({ mutationFn: trackDisqualification })
}
