import { DisqualificationReason } from './disqualifications'
import { toCents } from './general'
import { track } from './analytics'

type PanelProvider =
  | 'lucid'
  | 'mfour'
  | 'mindforce'
  | 'purespectrum'
  | 'repdata'
  | 'ROI'
  | 'symmetric'

export type RespondentID =
  | {
      respondentIncentive: number | null
      lucidRid: string
      lucidSurveyId: string
      Sid: 'lucid'
    }
  | {
      lucidRid: string
      Sid:
        | 'mfour'
        | 'mindforce'
        | 'purespectrum'
        | 'repdata'
        | 'ROI'
        | 'symmetric'
    }
  | { lucidRid: string }
  | undefined

const REQUIRED_QUERY_PARAMS = {
  lucid: ['lucidSurveyId', 'RID'],
  mfour: ['RID'],
  mindforce: ['RID'],
  purespectrum: ['ps_hash', 'RID'],
  repdata: ['RID'],
  ROI: ['RID'],
  symmetric: ['RID'],
} as const satisfies Record<PanelProvider, string[]>

const REDIRECT_URLS = {
  lucid: {
    COMPLETION: 'https://notch.insights.supply/cb',
    DISQUALIFICATION: 'https://samplicio.us/s/ClientCallBack.aspx',
  },
  mfour: 'https://www.surveysonthego.net/survey',
  mindforce: 'https://insightsenabled.mindforceresearch.in/postredirect',
  purespectrum: 'https://spectrumsurveys.com/surveydone',
  repdata: 'https://www.rdsecured.com/return',
  ROI: 'http://research.roirocket.com/surveyresults.aspx',
  symmetric: 'https://icion.decisionanalyst.com/l/exit',
} as const satisfies Record<PanelProvider, string | Record<string, string>>

type LucidTermCode = '20' | '30' | '40'
type MfourTermCode = 'overquota' | 'qualityscreen' | 'terminate'
type MindforceTermCode = '20' | '30' | '40'
type PureSpectrumTermCode = '17' | '18' | '20'
type RepDataTermCode = '2000' | '3000' | '4000'
type RoiRocketTermCode = 'D' | 'Q' | 'R' | 'T'
type SymmetricTermCode =
  | 'Did Not Qualify'
  | 'OverQuota'
  | 'Quality'
  | 'Security'

type RedirectCodeForProvider<Provider extends PanelProvider> =
  Provider extends 'lucid'
    ? LucidTermCode
    : Provider extends 'mfour'
      ? MfourTermCode
      : Provider extends 'mindforce'
        ? MindforceTermCode
        : Provider extends 'purespectrum'
          ? PureSpectrumTermCode
          : Provider extends 'repdata'
            ? RepDataTermCode
            : Provider extends 'ROI'
              ? RoiRocketTermCode
              : Provider extends 'symmetric'
                ? SymmetricTermCode
                : never

/**
 * See mapping in our Notion documentation: https://www.notion.so/useglass/Disqualifications-99311333c6d94c48b048048693cbd527.
 */
const TACTIC_TO_REDIRECT_CODE = {
  lucid: {
    audience_quota: '40',
    bad_respondent: '30',
    copy_paste: '30',
    presurvey_questionnaire: '30',
    question_quota: '20',
    variable_quota: '20',
    wave_quota: '40',
  },
  mfour: {
    audience_quota: 'overquota',
    bad_respondent: 'qualityscreen',
    copy_paste: 'qualityscreen',
    presurvey_questionnaire: 'qualityscreen',
    question_quota: 'terminate',
    variable_quota: 'terminate',
    wave_quota: 'overquota',
  },
  mindforce: {
    audience_quota: '40',
    bad_respondent: '30',
    copy_paste: '30',
    presurvey_questionnaire: '30',
    question_quota: '20',
    variable_quota: '20',
    wave_quota: '40',
  },
  purespectrum: {
    audience_quota: '17',
    bad_respondent: '20',
    copy_paste: '20',
    presurvey_questionnaire: '20',
    question_quota: '18',
    variable_quota: '18',
    wave_quota: '18',
  },
  repdata: {
    audience_quota: '2000',
    bad_respondent: '3000',
    copy_paste: '3000',
    presurvey_questionnaire: '3000',
    question_quota: '2000',
    variable_quota: '2000',
    wave_quota: '4000',
  },
  ROI: {
    audience_quota: 'D',
    bad_respondent: 'R',
    copy_paste: 'R',
    presurvey_questionnaire: 'R',
    question_quota: 'T',
    variable_quota: 'T',
    wave_quota: 'D',
  },
  symmetric: {
    audience_quota: 'OverQuota',
    bad_respondent: 'Security',
    copy_paste: 'Quality',
    presurvey_questionnaire: 'Quality',
    question_quota: 'Did Not Qualify',
    variable_quota: 'Did Not Qualify',
    wave_quota: 'OverQuota',
  },
} as const satisfies Record<
  PanelProvider,
  Record<
    DisqualificationReason['tactic'],
    RedirectCodeForProvider<PanelProvider>
  >
>

export function areParametersValid({
  searchParams,
}: {
  searchParams: URLSearchParams
}) {
  const provider = searchParams.get('provider') as PanelProvider
  const requiredQueryParams = provider
    ? REQUIRED_QUERY_PARAMS[provider]
    : undefined

  return (
    requiredQueryParams?.every((param) => {
      return !!searchParams.get(param)
    }) ?? true
  )
}

export function getRespondentID({
  searchParams,
}: {
  searchParams: URLSearchParams
}): RespondentID {
  const provider = searchParams.get('provider') as PanelProvider
  const respondentID = searchParams.get('RID')

  if (provider === 'lucid') {
    return {
      respondentIncentive: toCents(searchParams.get('respondentIncentive')),
      lucidRid: respondentID ?? '',
      lucidSurveyId: searchParams.get('lucidSurveyId') ?? '',
      Sid: provider,
    }
  } else if (
    provider === 'mfour' ||
    provider === 'mindforce' ||
    provider === 'purespectrum' ||
    provider === 'repdata' ||
    provider === 'ROI' ||
    provider === 'symmetric'
  ) {
    return { lucidRid: respondentID ?? '', Sid: provider }
  } else if (respondentID) {
    return { lucidRid: respondentID }
  }
}

export function redirectCompleted({
  isTesting,
  searchParams,
}: {
  isTesting: boolean
  searchParams: URLSearchParams
}) {
  const provider = searchParams.get('provider') as PanelProvider
  const respondentID = searchParams.get('RID')

  if (respondentID) {
    if (provider === 'lucid') {
      return redirectCompletedLucid({ respondentID })
    } else if (provider === 'mfour') {
      return redirectCompletedMfour({ respondentID })
    } else if (provider === 'mindforce') {
      return redirectCompletedMindforce({ respondentID })
    } else if (provider === 'purespectrum') {
      return redirectCompletedPureSpectrum({
        hash: searchParams.get('ps_hash') ?? '',
        respondentID,
      })
    } else if (provider === 'repdata') {
      return redirectCompletedRepData({ respondentID })
    } else if (provider === 'ROI') {
      return redirectCompletedROIRocket({ respondentID })
    } else if (provider === 'symmetric') {
      return redirectCompletedSymmetric({ respondentID })
    }
  }

  if (!isTesting) {
    track('Failed Redirect')
  }
}

function redirectCompletedLucid({ respondentID }: { respondentID: string }) {
  const completionParams = new URLSearchParams([
    ['token', 'a29ae3ef-4439-49d7-89df-9d9dacb63878'],
    ['RID', respondentID],
  ])

  window.location.href = `${REDIRECT_URLS.lucid.COMPLETION}?${completionParams}`
}

function redirectCompletedMfour({ respondentID }: { respondentID: string }) {
  const completionParams = new URLSearchParams([['ID', respondentID]])

  window.location.href = `${REDIRECT_URLS.mfour}/complete?${completionParams}`
}

function redirectCompletedMindforce({
  respondentID,
}: {
  respondentID: string
}) {
  const completionParams = new URLSearchParams([
    ['identifier', respondentID],
    ['status', '10'],
  ])

  window.location.href = `${REDIRECT_URLS.mindforce}?${completionParams}`
}

function redirectCompletedPureSpectrum({
  hash,
  respondentID,
}: {
  hash: string
  respondentID: string
}) {
  const completionParams = new URLSearchParams([
    ['transaction_id', respondentID],
    ['st', '21'],
    ['ps_hash', hash],
  ])

  window.location.href = `${REDIRECT_URLS.purespectrum}?${completionParams}`
}

function redirectCompletedRepData({ respondentID }: { respondentID: string }) {
  const completionParams = new URLSearchParams([
    ['rid', respondentID],
    ['inbound_code', '1000'],
  ])

  window.location.href = `${REDIRECT_URLS.repdata}?${completionParams}`
}

function redirectCompletedROIRocket({
  respondentID,
}: {
  respondentID: string
}) {
  const completionParams = new URLSearchParams([
    ['vid', respondentID],
    ['ss', 'S'],
  ])

  window.location.href = `${REDIRECT_URLS.ROI}?${completionParams}`
}

function redirectCompletedSymmetric({
  respondentID,
}: {
  respondentID: string
}) {
  const completionParams = new URLSearchParams([
    ['MID', respondentID],
    ['status', 'Complete'],
  ])

  window.location.href = `${REDIRECT_URLS.symmetric}?${completionParams}`
}

export function redirectDisqualified({
  disqualificationReason,
  isTesting,
  searchParams,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  isTesting: boolean
  searchParams: URLSearchParams
}) {
  const provider = searchParams.get('provider') as PanelProvider
  const respondentID = searchParams.get('RID')

  if (respondentID) {
    const opts = { disqualificationReason, respondentID }

    if (provider === 'lucid') {
      return redirectDisqualifiedLucid(opts)
    } else if (provider === 'mfour') {
      return redirectDisqualifiedMfour(opts)
    } else if (provider === 'mindforce') {
      return redirectDisqualifiedMindforce(opts)
    } else if (provider === 'purespectrum') {
      return redirectDisqualifiedPureSpectrum(opts)
    } else if (provider === 'repdata') {
      return redirectDisqualifiedRepData(opts)
    } else if (provider === 'ROI') {
      return redirectDisqualifiedROIRocket(opts)
    } else if (provider === 'symmetric') {
      return redirectDisqualifiedSymmetric(opts)
    }
  }

  if (!isTesting) {
    track('Failed Redirect')
  }
}

function redirectDisqualifiedLucid({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic ? TACTIC_TO_REDIRECT_CODE.lucid[tactic] : '20'
  const disqualParams = new URLSearchParams([
    ['RID', respondentID],
    ['RIS', redirectCode],
  ])

  window.location.href = `${REDIRECT_URLS.lucid.DISQUALIFICATION}?${disqualParams}`
}

function redirectDisqualifiedMfour({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic
    ? TACTIC_TO_REDIRECT_CODE.mfour[tactic]
    : 'terminate'
  const disqualParams = new URLSearchParams([['ID', respondentID]])

  window.location.href = `${REDIRECT_URLS.mfour}/${redirectCode}?${disqualParams}`
}

function redirectDisqualifiedMindforce({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic ? TACTIC_TO_REDIRECT_CODE.mindforce[tactic] : '20'
  const disqualParams = new URLSearchParams([
    ['identifier', respondentID],
    ['status', redirectCode],
  ])

  window.location.href = `${REDIRECT_URLS.mindforce}?${disqualParams}`
}

function redirectDisqualifiedPureSpectrum({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic
    ? TACTIC_TO_REDIRECT_CODE.purespectrum[tactic]
    : '18'
  const disqualParams = new URLSearchParams([
    ['transaction_id', respondentID],
    ['st', redirectCode],
  ])

  window.location.href = `${REDIRECT_URLS.purespectrum}?${disqualParams}`
}

function redirectDisqualifiedRepData({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic ? TACTIC_TO_REDIRECT_CODE.repdata[tactic] : '2000'
  const disqualParams = new URLSearchParams([
    ['rid', respondentID],
    ['inbound_code', redirectCode],
  ])

  window.location.href = `${REDIRECT_URLS.repdata}?${disqualParams}`
}

function redirectDisqualifiedROIRocket({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic ? TACTIC_TO_REDIRECT_CODE.ROI[tactic] : 'T'
  const disqualParams = new URLSearchParams([
    ['vid', respondentID],
    ['ss', redirectCode],
  ])

  window.location.href = `${REDIRECT_URLS.ROI}?${disqualParams}`
}

function redirectDisqualifiedSymmetric({
  disqualificationReason,
  respondentID,
}: {
  disqualificationReason?: { tactic: DisqualificationReason['tactic'] }
  respondentID: string
}) {
  const { tactic } = disqualificationReason || {}
  const redirectCode = tactic
    ? TACTIC_TO_REDIRECT_CODE.symmetric[tactic]
    : 'Did Not Qualify'
  const disqualParams = new URLSearchParams([
    ['MID', respondentID],
    ['status', redirectCode],
  ])

  window.location.href = `${REDIRECT_URLS.symmetric}?${disqualParams}`
}
