import { Question } from 'utils/questions'
import { titlesFromSet } from 'utils/general'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented, { NestedListItem } from './ListDiscIndented'

const QuotasOptions = ({ question }: { question: Question }) => {
  if (
    (question.type !== 'multipleChoice' && question.type !== 'ranking') ||
    !question.constraints.optionQuotas
  ) {
    return null
  }

  const { cantPickOptionIDs, mustPickOptionIDs } =
    question.constraints.optionQuotas

  if (cantPickOptionIDs.size === 0 && mustPickOptionIDs.length === 0) {
    return null
  }

  return (
    <FeatureDisplay title="Quotas">
      <ListDiscIndented>
        {cantPickOptionIDs.size > 0 && (
          <li>
            <NestedListItem title="Can't Pick">
              {titlesFromSet({
                ids: cantPickOptionIDs,
                resources: question.options,
              }).map((title) => (
                <li key={title}>{title}</li>
              ))}
            </NestedListItem>
          </li>
        )}
        {mustPickOptionIDs.length > 0 && (
          <li>
            <NestedListItem title="Must Pick">
              {mustPickOptionIDs
                .map((optionsSet) =>
                  titlesFromSet({
                    ids: optionsSet,
                    resources: question.options,
                  }).join(', OR '),
                )
                .filter(Boolean)
                .map((title) => (
                  <li key={title}>{title}</li>
                ))}
            </NestedListItem>
          </li>
        )}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default QuotasOptions
