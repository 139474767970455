import { Question } from './questions'

/**
 * Returns an error message if the user has not viewed a required concept.
 */
export function getRequireViewConceptError({
  question,
}: {
  question: Question
}) {
  if (
    question.concepts.length === 0 ||
    !question.constraints.requireConceptView
  ) {
    return ''
  }

  return question.concepts[0].viewed
    ? ''
    : 'Please zoom in on the concept before continuing.'
}

/**
 * Returns an error message if the user has not viewed all the required images.
 */
export function getRequireViewImagesError({
  question,
}: {
  question: Question
}) {
  if (
    (question.type !== 'multipleChoice' && question.type !== 'ranking') ||
    !question.constraints.requireImageViews
  ) {
    return ''
  }

  const hasViewedAllRequiredImages = question.options.every((option) => {
    if (option.type !== 'image' || !option.constraints.requireView) {
      return true
    }

    return option.image.viewed
  })

  return hasViewedAllRequiredImages
    ? ''
    : 'Please zoom in on all images before continuing.'
}
