import { InputHTMLAttributes } from 'react'
import { ValidationValueMessage } from 'react-hook-form'

import { buildCarryForwardFeature, CarryForwardFeature } from './carryForward'
import {
  buildConceptDisplayLogic,
  buildOptionsDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildExclusiveOptionIDs } from './exclusive'
import { buildOptionQuotas, OptionQuotasConstraint } from './quotas'
import {
  buildOptionDisplayXOfYFeature,
  DisplayXOfYFeature,
} from './displayXOfY'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildRandomizeOptionsFeature, RandomizeFeature } from './randomize'
import {
  buildTestLabel,
  getConstraintMinMax,
  getFeatureNumberRangeValue,
  hasFeature,
  OPTION_TYPES,
  shouldHideImageTitles,
} from './questions'
import { Concept, getConcepts } from './concepts'
import { getMinMaxError } from './minMax'
import { getRequireSumError } from './sums'
import {
  getRequireViewConceptError,
  getRequireViewImagesError,
} from './requireViewing'
import { Image } from './media'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'
import { Survey } from './surveys'

export type OptionImage = {
  constraints: {
    max: ValidationValueMessage<number> | undefined
    min: ValidationValueMessage<number> | undefined
    requireView: boolean
  }
  features: { hideTitle: boolean }
  freeTextInputType: InputHTMLAttributes<HTMLInputElement>['type']
  id: string
  image: Image & { viewed: boolean }
  title: string
  type: 'image'
}

export type OptionText = {
  constraints: {
    max: ValidationValueMessage<number> | undefined
    min: ValidationValueMessage<number> | undefined
  }
  freeTextInputType: InputHTMLAttributes<HTMLInputElement>['type']
  id: string
  title: string
  type: 'text'
}

export type Question = {
  concepts: Concept[]
  constraints: {
    exclusiveOptionIDs: Set<string>
    max: number | null
    min: number | null
    optionQuotas?: OptionQuotasConstraint
    requireConceptView: boolean
    requireImageViews: boolean
    singleChoice: boolean
    sum: number | null
  }
  directions: string
  features: {
    carryForward: CarryForwardFeature | undefined
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    displayXOfY: DisplayXOfYFeature | undefined
    optionDisplayLogic: DisplayLogicByResource
    pipeConcept: PipeConceptFeature | undefined
    randomizeOptions: RandomizeFeature | undefined
  }
  id: string
  options: (OptionText | OptionImage)[]
  // The response is a set of selected option IDs. A respondent can select more than one
  // option if the multiple response feature is enabled.
  response: Set<string>
  // This is a mapping of option ID to the respondent's entered free-text.
  responseFreeText: Record<string, string>
  testing: { label: string }
  title: string
  type: 'multipleChoice'
}

export function canContinue({
  curResponse,
  curResponseFreeText,
  question,
}: {
  curResponse: Question['response']
  curResponseFreeText: Question['responseFreeText']
  question: Question
}) {
  return (
    curResponse.size > 0 &&
    !getMinMaxError({ curResponse, question }) &&
    // We only enforce a required sum if the user selected a free-text option.
    // The question may be configured with a mix of free-text and non free-text options -
    // the sum only applies to free-text ones.
    (!hasSelectedFreeTextOption({ curResponse, question }) ||
      !getRequireSumError({
        curResponse: curResponseFreeText,
        question,
      })) &&
    !getRequireViewConceptError({ question }) &&
    !getRequireViewImagesError({ question })
  )
}

export function getDisplayError({ question }: { question: Question }) {
  return question.options.length > 0 ? false : 'no options'
}

export function getQuestion({
  id,
  question,
  questionsConfiguration,
  survey,
}: {
  id: string
  question: QuestionAPI
  questionsConfiguration: Record<number, QuestionAPI>
  survey: Survey
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      exclusiveOptionIDs: buildExclusiveOptionIDs({ question }),
      max: getFeatureNumberRangeValue({ code: 'MCUL01', question }),
      min: getFeatureNumberRangeValue({ code: 'MCLL01', question }),
      optionQuotas: buildOptionQuotas({ question, survey }),
      requireConceptView: hasFeature({ code: 'VAL02', question }),
      requireImageViews: hasFeature({ code: 'VAL01', question }),
      singleChoice: !isMultipleResponseQuestion(question),
      sum: getFeatureNumberRangeValue({ code: 'SUM01', question }),
    },
    directions: question.description,
    features: {
      carryForward: buildCarryForwardFeature({
        question,
        questionsConfiguration,
        useNewMatrixOptions: survey.features.useNewMatrixOptions,
      }),
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      displayXOfY: buildOptionDisplayXOfYFeature({ question }),
      optionDisplayLogic: buildOptionsDisplayLogic({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
      randomizeOptions: buildRandomizeOptionsFeature({ question }),
    },
    id,
    options: question.options.map((option) => {
      const { max, min } = getConstraintMinMax(question)
      const constraints = { max, min }

      // The only way to turn free text responses into number types is to have
      // a min and max constraint.
      const freeTextInputType = option.isFreeTextOption
        ? max || min
          ? 'number'
          : 'text'
        : undefined

      if (question.contentTypeId === OPTION_TYPES.IMAGE && option.dataUrl) {
        const title = option.description ?? ''

        return {
          constraints: { ...constraints, requireView: option.viewRequired },
          features: { hideTitle: shouldHideImageTitles(question) },
          freeTextInputType,
          id: `${option.id}`,
          image: {
            alt: title,
            publicID: option.dataUrl.public_id,
            viewed: false,
          },
          title,
          type: 'image' as const,
        } satisfies OptionImage
      }

      return {
        constraints,
        freeTextInputType,
        id: `${option.id}`,
        title: option.title,
        type: 'text' as const,
      } satisfies OptionText
    }),
    response: new Set<string>(),
    responseFreeText: {},
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'multipleChoice' as const,
  } satisfies Question
}

export function hasSelectedFreeTextOption({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  return question.options.some((option) => {
    return !!option.freeTextInputType && curResponse.has(option.id)
  })
}

export function isMultipleResponseQuestion(question: QuestionAPI) {
  return !!hasFeature({ code: 'MRS01', question })
}

export function isOptionDisabled({
  curResponse,
  option,
  question,
}: {
  curResponse: Question['response']
  option: Question['options'][number]
  question: Question
}) {
  // If the option has been selected, we don't want to disable it because we want the
  // user to be able to deselect it (in the case of multiple response questions).
  if (curResponse.has(option.id)) {
    return false
  }

  if (
    question.constraints.max &&
    curResponse.size === question.constraints.max
  ) {
    return true
  }

  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  const hasEveryRequiredImageBeenViewed = question.options.every(
    (option) =>
      option.type === 'text' ||
      !option.constraints.requireView ||
      option.image.viewed,
  )
  if (
    question.constraints.requireImageViews &&
    !hasEveryRequiredImageBeenViewed
  ) {
    return true
  }

  const isAnExclusiveOptionSelected = Array.from(
    question.constraints.exclusiveOptionIDs,
  ).some((id) => {
    return curResponse.has(id)
  })
  if (isAnExclusiveOptionSelected) {
    return true
  }

  return false
}
