import {
  ActorRefFrom,
  assign,
  choose,
  createMachine,
  raise,
  sendParent,
  spawn,
} from 'xstate'
import { cloneDeep, keyBy } from 'lodash-es'
import * as Sentry from '@sentry/react'

import { applyBlockFeatures, BlockSetMonadic } from 'utils/questionBlocks'
import {
  applyQuestionFeatures,
  getDisplayError,
  getIDParts,
  getQuestion,
  Question,
} from 'utils/questions'
import {
  BATCH_FLUSH_INTERVAL,
  disableTracking,
  questionToAnalyticsQuestion,
  setProperty,
  track,
} from 'utils/analytics'
import {
  buildSurveyItems,
  getNextQuestionID,
  getQuestionIDsFromSurveyItem,
  getSurvey,
  getSurveyItemIndexFromQuestionID,
  isBlockSet,
  isQualityCheckEnabled,
  Survey,
  SurveyItem,
} from 'utils/surveys'
import { createUser } from 'services/glass-api/users'
import {
  DisqualificationReason,
  DisqualificationReasonResearchDefender,
} from 'utils/disqualifications'
import { getEnvVar } from 'utils/env'
import { getQualityDisqualification } from 'utils/quality'
import { getQuotaDisqualification } from 'utils/quotas'
import { RespondentID } from 'utils/panelProviders'
import { searchRespondent } from 'services/research-defender/search'
import { SubmitAnswersResponse } from 'services/glass-api/surveys'
import {
  Survey as SurveyAPI,
  SurveyQualityCheck,
} from 'types/glass-api/domainModels'

type Context = {
  curQuestionID: string
  disqualificationReason: DisqualificationReason | undefined
  isTesting: boolean
  monadicBlocks: Record<string, BlockSetMonadic>
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  survey: Survey
  surveyItems: SurveyItem[]
  timing: { end: number | undefined; start: number }
  userActor: ActorRefFrom<typeof createUserMachine> | null
  userID: number | null
}

type Events =
  | { type: 'ANALYTICS_FLUSH_DELAY_MET' }
  | {
      clearType: 'current' | 'current-subsequent'
      questionID: string
      type: 'CLICKED_CLEAR_RESPONSE'
    }
  | { type: 'CLICKED_CLOSE_OVERLAY' }
  | { type: 'CLICKED_TEST_NEXT' }
  | { type: 'CLICKED_TEST_PREVIOUS' }
  | {
      newQuestion: Question
      type: 'COMPLETED_QUESTION'
    }
  | {
      freeText: string
      type: 'COPY_PASTED'
    }
  | { questionID: string; type: 'JUMP_TO_QUESTION' }
  | {
      disqualificationReason: DisqualificationReason | undefined
      type: 'USER_CREATED'
      userID: number | null
    }
  | { conceptID: string; questionID: string; type: 'VIEWED_CONCEPT' }
  | { optionID: string; questionID: string; type: 'VIEWED_IMAGE' }

type Services = {
  saveAnswers: { data: SubmitAnswersResponse }
}

// We add an additional 250ms to try to give the batch flush time to complete.
export const ANALYTICS_FLUSH_DELAY = BATCH_FLUSH_INTERVAL + 250

export function createSurveyMachine({
  isTestMode,
  respondentID,
  startingQuestionID,
  surveyAPI,
}: {
  isTestMode: boolean
  respondentID: RespondentID
  startingQuestionID?: string
  surveyAPI: SurveyAPI
}) {
  const survey = getSurvey({ survey: surveyAPI })

  const questionsConfiguration = keyBy(surveyAPI.questions, 'id')
  const initialSurveyItems = buildSurveyItems({ survey: surveyAPI })

  const monadicBlocks: Context['monadicBlocks'] = {}
  initialSurveyItems.forEach((item) => {
    if (item.type === 'blockSetMonadic') {
      monadicBlocks[item.data.id] = item.data
    }
  })

  const questionsInitial: Context['questionsInitial'] = {}
  initialSurveyItems.forEach((item) => {
    const questionIDs = getQuestionIDsFromSurveyItem({
      surveyItem: item,
    })

    questionIDs.forEach((id) => {
      const [apiID] = getIDParts({ id })

      questionsInitial[id] = getQuestion({
        id,
        question: questionsConfiguration[apiID],
        questionsConfiguration,
        survey,
      })
    })
  })

  if (initialSurveyItems[0].type === 'blockSet') {
    initialSurveyItems[0].data = applyBlockFeatures({
      blockSet: initialSurveyItems[0].data,
      monadicBlocks,
      questions: questionsInitial,
    })
  }

  const firstQuestionID =
    startingQuestionID && questionsInitial[startingQuestionID] && isTestMode
      ? startingQuestionID
      : initialSurveyItems[0].type === 'question'
        ? initialSurveyItems[0].data.id
        : initialSurveyItems[0].data.blocks[0].questionIDs[0]

  const initialSurveyItemIndex = getSurveyItemIndexFromQuestionID({
    questionID: firstQuestionID,
    surveyItems: initialSurveyItems,
  })
  const initialSurveyItem = initialSurveyItems[initialSurveyItemIndex]

  const questionsCurrent = cloneDeep(questionsInitial)
  questionsCurrent[firstQuestionID] = applyQuestionFeatures({
    monadicBlocks,
    nextQuestion: questionsCurrent[firstQuestionID],
    nextSurveyItem: initialSurveyItem,
    questions: questionsCurrent,
  })

  if (isTestMode) {
    disableTracking()
  }

  setProperty('surveyID', survey.id)
  setProperty('respondentID', respondentID?.lucidRid ?? '')
  track('Started Survey')

  return createMachine(
    {
      context: {
        curQuestionID: firstQuestionID,
        disqualificationReason: undefined,
        isTesting: isTestMode,
        monadicBlocks,
        questionsCurrent,
        questionsInitial,
        survey,
        surveyItems: initialSurveyItems,
        timing: { end: undefined, start: window.performance.now() },
        userActor: null,
        userID: null,
      },
      id: 'survey',
      initial: 'checkingSurveyClosed',
      predictableActionArguments: true,
      schema: {
        context: {} as Context,
        events: {} as Events,
        services: {} as Services,
      },
      tsTypes: {} as import('./survey.typegen').Typegen0,

      states: {
        checkingSurveyClosed: {
          always: [
            {
              cond: 'isSurveyClosed',
              target: 'surveyClosed',
            },
            {
              actions: ['spawnUserActor'],
              cond: 'isNotTesting',
              target: 'respondingToQuestion',
            },
            { target: 'respondingToQuestion' },
          ],
        },
        surveyClosed: {
          entry: [
            'updateEndTime',
            'trackSurveyClosed',
            choose([{ cond: 'isNotTesting', actions: 'spawnUserActor' }]),
          ],
          on: {
            ANALYTICS_FLUSH_DELAY_MET: {
              target: 'trackDisqualification',
            },
            CLICKED_CLOSE_OVERLAY: {
              cond: 'isTesting',
              target: 'respondingToQuestion',
            },
            USER_CREATED: {
              actions: [
                'setWaveQuotaDisqualification',
                'trackDisqualified',
                // We send a delayed event after our analytics have a chance to flush in case
                // the next action is a redirect.
                'sendAnalyticsFlushDelayMet',
              ],
            },
          },
        },
        respondingToQuestion: {
          entry: ['trackStartedQuestion'],
          on: {
            CLICKED_CLEAR_RESPONSE: {
              actions: ['clearResponses'],
              cond: 'isTesting',
            },
            CLICKED_TEST_NEXT: {
              target: 'findingNextQuestion',
            },
            CLICKED_TEST_PREVIOUS: {
              cond: 'hasPreviousQuestion',
              target: 'findingPreviousQuestion',
            },
            COMPLETED_QUESTION: {
              actions: ['saveQuestionResponse'],
              target: 'checkingQualification',
            },
            COPY_PASTED: {
              actions: ['setPasteDisqualification'],
              cond: 'isCopyPasteCheckEnabled',
              target: 'disqualified',
            },
            JUMP_TO_QUESTION: {
              actions: ['setCurQuestionID'],
              cond: 'isTesting',
            },
            VIEWED_CONCEPT: { actions: ['saveConceptViewed'] },
            VIEWED_IMAGE: { actions: ['saveImageViewed'] },
          },
        },
        checkingQualification: {
          always: [
            {
              actions: ['setQualityDisqualification'],
              cond: 'hasQualityDisqualification',
              target: 'disqualified',
            },
            {
              actions: ['updateDisqualificationReason'],
              cond: 'hasQuotaDisqualification',
              target: 'disqualified',
            },
            {
              target: 'findingNextQuestion',
            },
          ],
        },
        findingPreviousQuestion: {
          entry: ['trackCompletedQuestion', 'goToPreviousQuestion'],
          always: [
            {
              cond: 'isCurQuestionDisplayable',
              target: 'respondingToQuestion',
            },
            {
              target: 'findingPreviousQuestion',
            },
          ],
        },
        findingNextQuestion: {
          entry: ['trackCompletedQuestion'],
          always: [
            {
              actions: ['goToNextQuestion'],
              cond: 'hasNextQuestion',
              target: 'respondingToQuestion',
            },
            {
              cond: 'isTesting',
              target: 'testingCompleted',
            },
            {
              target: 'savingAnswers',
            },
          ],
        },
        savingAnswers: {
          entry: ['updateEndTime'],
          invoke: {
            src: 'saveAnswers',
            onDone: [
              { cond: 'wereAnswersSaved', target: 'completed' },
              {
                actions: ['setWaveQuotaDisqualification'],
                target: 'disqualified',
              },
            ],
            onError: {
              actions: ['captureSaveAnswersError'],
              target: 'disqualified',
            },
          },
        },
        disqualified: {
          entry: [
            'trackCompletedQuestion',
            'updateEndTime',
            'trackDisqualified',
            // If we're not testing, we send a delayed event after our analytics have a chance
            // to flush in case the next action is a redirect.
            choose([
              { cond: 'isNotTesting', actions: 'sendAnalyticsFlushDelayMet' },
            ]),
          ],
          on: {
            ANALYTICS_FLUSH_DELAY_MET: {
              target: 'trackDisqualification',
            },
            CLICKED_CLOSE_OVERLAY: {
              cond: 'isTesting',
              target: 'respondingToQuestion',
            },
          },
        },
        trackDisqualification: {
          invoke: {
            src: 'trackDisqualification',
            onDone: { target: 'redirectingDisqualified' },
            onError: { target: 'redirectingDisqualified' },
          },
        },
        completed: {
          // On entry to this state, we send a delayed event to give our analytics time to flush.
          entry: ['trackCompletedSurvey', 'sendAnalyticsFlushDelayMet'],
          on: {
            ANALYTICS_FLUSH_DELAY_MET: {
              actions: ['redirectCompleted'],
              target: 'redirectingCompleted',
            },
          },
        },
        redirectingDisqualified: {
          entry: ['redirectDisqualified'],
          type: 'final',
        },
        redirectingCompleted: { type: 'final' },
        testingCompleted: {
          entry: ['trackCompletedSurvey'],
          on: {
            CLICKED_CLOSE_OVERLAY: {
              cond: 'isTesting',
              target: 'respondingToQuestion',
            },
          },
        },
      },

      on: {
        USER_CREATED: [
          {
            actions: ['setDisqualificationReason'],
            cond: 'shouldDisqualify',
            target: 'disqualified',
          },
          {
            actions: ['setUserID'],
          },
        ],
      },
    },
    {
      actions: {
        captureSaveAnswersError: (_ctx, event) => {
          Sentry.captureException(event.data)
        },
        clearResponses: assign({
          questionsCurrent: (ctx, event) => {
            const { curQuestionID, questionsCurrent, surveyItems } = ctx
            const { clearType, questionID: questionIDToStartAt } = event

            const currentSurveyItemIndex = getSurveyItemIndexFromQuestionID({
              questionID: curQuestionID,
              surveyItems,
            })
            const clickedSurveyItemIndex = getSurveyItemIndexFromQuestionID({
              questionID: questionIDToStartAt,
              surveyItems,
            })

            // We only allow clearing of responses for the current question or subsequent ones.
            // Clearning previous responses could put the respondent in an invalid state because
            // of features that rely on previous responses. It could be possible in the future but
            // it would be more more complicated to implement (and provides questionable value, at least
            // at the time of writing).
            if (clickedSurveyItemIndex < currentSurveyItemIndex) {
              return questionsCurrent
            }

            if (clearType === 'current') {
              return {
                ...questionsCurrent,
                [questionIDToStartAt]: questionsInitial[questionIDToStartAt],
              }
            }

            const newQuestions = cloneDeep(questionsCurrent)
            const newSurveyItems = cloneDeep(surveyItems)

            let nextSurveyItemIndex = getSurveyItemIndexFromQuestionID({
              questionID: questionIDToStartAt,
              surveyItems: newSurveyItems,
            })
            let nextSurveyItem = newSurveyItems[nextSurveyItemIndex]

            // Starting at the question the user chose to clear responses for, we reset the
            // question configuration, responses, and the survey items (in the case of a block
            // set).
            while (nextSurveyItem) {
              const questionIDs = getQuestionIDsFromSurveyItem({
                surveyItem: nextSurveyItem,
              })

              // Reset all the question configuration and responses for the question.
              for (const questionID of questionIDs) {
                newQuestions[questionID] = questionsInitial[questionID]
              }

              // If this is a block set, we want to reset all the blocks as well so a different
              // configuration of blocks can be shown the next time through (depending on configured
              // features like randomization or display X of Y blocks).
              if (isBlockSet(nextSurveyItem)) {
                newSurveyItems[nextSurveyItemIndex] =
                  initialSurveyItems[nextSurveyItemIndex]
              }

              nextSurveyItemIndex = nextSurveyItemIndex + 1
              nextSurveyItem = newSurveyItems[nextSurveyItemIndex]
            }

            return newQuestions
          },
        }),
        goToNextQuestion: assign((ctx) => {
          return getNextDisplayableQuestion({ ctx })
        }),
        goToPreviousQuestion: assign({
          curQuestionID: (ctx) => {
            const nextItem = getNextQuestionID({
              curQuestionID: ctx.curQuestionID,
              direction: 'previous',
              monadicBlocks: ctx.monadicBlocks,
              questions: ctx.questionsCurrent,
              surveyItems: ctx.surveyItems,
            })
            if (!nextItem.nextQuestionID) {
              return ctx.curQuestionID
            }

            return nextItem.nextQuestionID
          },
        }),
        saveConceptViewed: assign({
          questionsCurrent: (ctx, event) => {
            const newQuestions = cloneDeep(ctx.questionsCurrent)
            const question = newQuestions[event.questionID]
            const concept = question.concepts.find(
              (concept) => concept.id === event.conceptID,
            )

            if (!concept) {
              return newQuestions
            }

            concept.viewed = true

            return newQuestions
          },
        }),
        saveImageViewed: assign({
          questionsCurrent: (ctx, event) => {
            const newQuestions = cloneDeep(ctx.questionsCurrent)
            const question = newQuestions[event.questionID]

            if (
              question.type !== 'multipleChoice' &&
              question.type !== 'ranking'
            ) {
              return newQuestions
            }

            const option = question.options.find(
              ({ id }) => id === event.optionID,
            )
            if (option?.type !== 'image') {
              return newQuestions
            }

            option.image.viewed = true

            return newQuestions
          },
        }),
        saveQuestionResponse: assign({
          questionsCurrent: (ctx, event) => {
            return {
              ...ctx.questionsCurrent,
              [event.newQuestion.id]: event.newQuestion,
            }
          },
        }),
        sendAnalyticsFlushDelayMet: raise(
          { type: 'ANALYTICS_FLUSH_DELAY_MET' },
          { delay: ANALYTICS_FLUSH_DELAY },
        ),
        setCurQuestionID: assign({
          curQuestionID: (_ctx, event) => {
            return event.questionID
          },
        }),
        setDisqualificationReason: assign({
          disqualificationReason: (_ctx, event) => {
            return event.disqualificationReason
          },
        }),
        setPasteDisqualification: assign({
          disqualificationReason: (_ctx, event) => {
            return {
              freeText: event.freeText,
              source: 'glass',
              tactic: 'copy_paste',
            } satisfies DisqualificationReason
          },
        }),
        setQualityDisqualification: assign({
          disqualificationReason: (ctx) => {
            return getQualityDisqualification({
              curQuestion: ctx.questionsCurrent[ctx.curQuestionID],
            })
          },
        }),
        setUserID: assign({
          userID: (_ctx, event) => {
            return event.userID
          },
        }),
        setWaveQuotaDisqualification: assign({
          disqualificationReason: () => {
            return {
              source: 'glass',
              tactic: 'wave_quota',
            } satisfies DisqualificationReason
          },
        }),
        spawnUserActor: assign({
          userActor: (context) =>
            spawn(
              createUserMachine({
                initialQualityChecks: context.survey.qualityChecks,
                respondentID,
                surveyHash: context.survey.hash,
                surveyID: context.survey.id,
              }),
            ),
        }),
        trackCompletedQuestion: (ctx) => {
          track(
            'Completed Question',
            questionToAnalyticsQuestion({
              ...ctx.questionsCurrent[ctx.curQuestionID],
              title: questionsInitial[ctx.curQuestionID].title,
            }),
          )
        },
        trackCompletedSurvey: (ctx) => {
          track('Completed Survey', {
            timeToComplete: getSurveyTimeToComplete(ctx),
          })
        },
        trackDisqualified: (ctx) => {
          track('Disqualified', {
            ...ctx.disqualificationReason,
            questionID: ctx.curQuestionID,
            questionTitle: questionsInitial[ctx.curQuestionID].title,
          })
        },
        trackStartedQuestion: (ctx) => {
          track('Started Question', {
            questionID: ctx.curQuestionID,
            questionTitle: questionsInitial[ctx.curQuestionID].title,
          })
        },
        trackSurveyClosed: () => {
          track('Displayed Survey Closed')
        },
        updateDisqualificationReason: assign({
          disqualificationReason: (ctx) => {
            return getQuotaDisqualification({
              curQuestion: ctx.questionsCurrent[ctx.curQuestionID],
              monadicBlocks: ctx.monadicBlocks,
              questions: ctx.questionsCurrent,
              survey: ctx.survey,
            })
          },
        }),
        updateEndTime: assign({
          timing: (ctx) => {
            return { ...ctx.timing, end: window.performance.now() }
          },
        }),
      },
      guards: {
        hasQualityDisqualification: (ctx) => {
          return !!getQualityDisqualification({
            curQuestion: ctx.questionsCurrent[ctx.curQuestionID],
          })
        },
        hasQuotaDisqualification: (ctx) => {
          return !!getQuotaDisqualification({
            curQuestion: ctx.questionsCurrent[ctx.curQuestionID],
            monadicBlocks: ctx.monadicBlocks,
            questions: ctx.questionsCurrent,
            survey: ctx.survey,
          })
        },
        hasNextQuestion: (ctx) => {
          const { curQuestionID } = getNextDisplayableQuestion({ ctx })

          return curQuestionID !== ctx.curQuestionID
        },
        hasPreviousQuestion: (ctx) => {
          return !!getNextQuestionID({
            curQuestionID: ctx.curQuestionID,
            direction: 'previous',
            monadicBlocks: ctx.monadicBlocks,
            questions: ctx.questionsCurrent,
            surveyItems: ctx.surveyItems,
          }).nextQuestionID
        },
        isCopyPasteCheckEnabled: (ctx) => {
          return isQualityCheckEnabled({
            qualityChecks: ctx.survey.qualityChecks,
            type: 'copy_paste',
          })
        },
        isCurQuestionDisplayable: (ctx) => {
          return !getDisplayError({
            monadicBlocks: ctx.monadicBlocks,
            question: ctx.questionsCurrent[ctx.curQuestionID],
            questions: ctx.questionsCurrent,
          })
        },
        isNotTesting: (ctx) => {
          return !ctx.isTesting
        },
        isSurveyClosed: () => {
          return (
            surveyAPI.status.name === 'completed' ||
            // We don't want non-test users to see draft surveys. You could imagine a survey
            // that is live in Lucid, gets set to draft, but the change doesn't reflect immediately
            // in Lucid which would still be sending respondents to the survey, which is now in draft.
            (!isTestMode && surveyAPI.status.name === 'draft')
          )
        },
        isTesting: (ctx) => {
          return ctx.isTesting
        },
        shouldDisqualify: (_ctx, event) => {
          return !!event.disqualificationReason
        },
        wereAnswersSaved: (_ctx, event) => {
          return 'success' in event.data && event.data.success
        },
      },
    },
  )
}

/**
 * Finds the next question according to the supplied order that is
 * displayable. Returns the next question ID, which will be unchanged
 * if there are no more displayable questions, and the updated questionsCurrent
 * and surveyItems state with question and block set features applied to intermediate
 * questions or block sets.
 */
function getNextDisplayableQuestion({ ctx }: { ctx: Context }) {
  const { curQuestionID, surveyItems } = ctx
  const questions = { ...ctx.questionsCurrent }

  let nextItem = getNextQuestionID({
    curQuestionID,
    direction: 'next',
    monadicBlocks: ctx.monadicBlocks,
    questions,
    surveyItems,
  })

  while (nextItem.nextQuestionID) {
    const nextQuestionID = nextItem.nextQuestionID
    const nextQuestion = questions[nextQuestionID]

    const nextSurveyItemIndex = getSurveyItemIndexFromQuestionID({
      questionID: nextQuestionID,
      surveyItems,
    })
    const nextSurveyItem = surveyItems[nextSurveyItemIndex]

    questions[nextQuestionID] = applyQuestionFeatures({
      monadicBlocks: ctx.monadicBlocks,
      nextQuestion,
      nextSurveyItem,
      questions,
    })

    // There could be any number of reasons a question is not considered displayable.
    // For example, a multiple choice question won't be displayable if it has no options
    // to display (e.g. it carried forward not selected options from a previous question
    // but all options were selected). We defer to the question itself to determine
    // if it is displayable.
    if (
      !getDisplayError({
        monadicBlocks: ctx.monadicBlocks,
        question: questions[nextQuestionID],
        questions,
      })
    ) {
      break
    }

    nextItem = getNextQuestionID({
      curQuestionID: nextQuestionID,
      direction: 'next',
      monadicBlocks: ctx.monadicBlocks,
      questions,
      surveyItems: nextItem.surveyItems,
    })
  }

  return {
    curQuestionID: nextItem.nextQuestionID ?? ctx.curQuestionID,
    questionsCurrent: questions,
    surveyItems: nextItem.surveyItems,
  }
}

export function getSurveyTimeToComplete(ctx: Context) {
  return ctx.timing.end
    ? Math.round((ctx.timing.end - ctx.timing.start) / 1000)
    : null
}

function createUserMachine({
  initialQualityChecks,
  respondentID,
  surveyHash,
  surveyID,
}: {
  initialQualityChecks: SurveyQualityCheck[]
  respondentID: RespondentID
  surveyHash: string
  surveyID: string
}) {
  return createMachine(
    {
      context: {
        disqualificationReason: undefined,
        qualityChecks: initialQualityChecks,
        userID: null,
      },
      id: 'user',
      initial: 'creatingUser',
      predictableActionArguments: true,
      schema: {
        context: {} as {
          disqualificationReason:
            | DisqualificationReasonResearchDefender
            | undefined
          // Some surveys may be configured to skip bad respondent or duplicate detection. We keep
          // track of the quality checks to enforce so we can skip validation if necessary.
          qualityChecks: SurveyQualityCheck[]
          userID: number | null
        },
        services: {} as {
          createUser: { data: Awaited<ReturnType<typeof createUser>> }
          validateUser: { data: Awaited<ReturnType<typeof searchRespondent>> }
        },
      },
      tsTypes: {} as import('./survey.typegen').Typegen1,

      states: {
        creatingUser: {
          invoke: {
            src: 'createUser',
            onDone: [
              {
                actions: ['setUserID'],
                cond: 'isResearchDefenderEnabled',
                target: 'validatingUser',
              },
              {
                actions: ['setUserID'],
                target: 'done',
              },
            ],
            onError: { target: 'done' },
          },
        },
        validatingUser: {
          invoke: {
            src: 'validateUser',
            onDone: { actions: ['setDisqualificationReason'], target: 'done' },
            onError: { target: 'done' },
          },
        },
        done: { entry: ['respondWithUser'], type: 'final' },
      },
    },
    {
      actions: {
        respondWithUser: sendParent((context) => {
          return {
            disqualificationReason: context.disqualificationReason,
            type: 'USER_CREATED',
            userID: context.userID,
          }
        }),
        setDisqualificationReason: assign({
          disqualificationReason: (context, event) => {
            const { Respondent, Surveys } = event.data
            const rdSurvey = Surveys[0]

            // 29 is an arbitrary number that Research Defender identified as the sweet spot.
            // See https://useglass.slack.com/archives/C055T9K8FTN/p1701804041058999.
            const isBadRespondent =
              Respondent.threat_potential_score >= 29 &&
              isQualityCheckEnabled({
                qualityChecks: context.qualityChecks,
                type: 'bad_respondent',
              })

            // The duplicate score is either 0 or 100, as of this writing.
            const isDuplicate =
              rdSurvey &&
              rdSurvey.duplicate_score >= 100 &&
              isQualityCheckEnabled({
                qualityChecks: context.qualityChecks,
                type: 'duplicate',
              })

            if (isBadRespondent || isDuplicate) {
              return {
                metadata: {
                  country_code: Respondent.country_code,
                  country_mismatch: rdSurvey?.country_mismatch,
                  duplicate_score: rdSurvey?.duplicate_score,
                  failure_reason: rdSurvey?.failure_reason,
                  respondent_ud: Respondent.respondent_ud,
                  threat_potential_score: Respondent.threat_potential_score,
                },
                source: 'research_defender',
                tactic: 'bad_respondent',
              } satisfies DisqualificationReasonResearchDefender
            }

            return context.disqualificationReason
          },
        }),
        setUserID: assign({
          userID: (_ctx, event) => {
            return event.data.id
          },
        }),
      },
      guards: {
        isResearchDefenderEnabled: (ctx) => {
          return (
            !!getEnvVar('RESEARCH_DEFENDER_HOST') &&
            (isQualityCheckEnabled({
              qualityChecks: ctx.qualityChecks,
              type: 'bad_respondent',
            }) ||
              isQualityCheckEnabled({
                qualityChecks: ctx.qualityChecks,
                type: 'duplicate',
              }))
          )
        },
      },
      services: {
        createUser: () => {
          return createUser({
            data: { ...(respondentID ?? {}), surveyHash },
          })
        },
        validateUser: (ctx) => {
          if (!ctx.userID) {
            return Promise.reject(
              new Error('Cannot validate user since no user ID was found.'),
            )
          }

          return searchRespondent({ userID: ctx.userID, surveyID })
        },
      },
    },
  )
}
