import { DisqualificationType } from 'types/glass-api/domainModels'
import { SearchRespondentFailureReason } from 'types/research-defender/domainModels'

export type DisqualificationReasonGlass = {
  freeText?: string
  source: 'glass'
  tactic:
    | 'audience_quota'
    | 'copy_paste'
    | 'presurvey_questionnaire'
    | 'question_quota'
    | 'variable_quota'
    | 'wave_quota'
}

export type DisqualificationReasonResearchDefender = {
  metadata: {
    country_code: string
    country_mismatch: 0 | 1
    duplicate_score: 0 | 100
    failure_reason: SearchRespondentFailureReason | ''
    respondent_ud: string
    threat_potential_score: number
  }
  source: 'research_defender'
  tactic: 'bad_respondent'
}

export type DisqualificationReason =
  | DisqualificationReasonGlass
  | DisqualificationReasonResearchDefender

export const DISQUALIFICATION_REASON_TO_LEGACY_REASON = {
  audience_quota: 'QUOTA',
  bad_respondent: 'CHECK',
  copy_paste: 'PASTE',
  presurvey_questionnaire: 'QUALITY',
  question_quota: 'QUOTA',
  variable_quota: 'QUOTA',
  wave_quota: 'QUOTA',
} as const satisfies Record<
  DisqualificationReason['tactic'],
  DisqualificationType
>

export const DISQUALIFICATION_TACTIC_READABLE = {
  audience_quota: 'Audience Quota',
  bad_respondent: 'Bad Respondent (as determined by Research Defender)',
  copy_paste: 'Copy-Paste',
  presurvey_questionnaire:
    'Presurvey Questionnaire (e.g. "Clock" screener question)',
  question_quota: 'Question Quota',
  variable_quota: 'Survey Variable Quota',
  wave_quota: 'Already have needed number of respondents for wave.',
} as const satisfies Record<DisqualificationReason['tactic'], string>

export function getReadableDisqualificationReason(
  disqualificationReason: DisqualificationReason | undefined,
) {
  if (!disqualificationReason) {
    return
  }

  const readableReason =
    DISQUALIFICATION_TACTIC_READABLE[disqualificationReason.tactic]
  if ('freeText' in disqualificationReason && disqualificationReason.freeText) {
    return `${readableReason}. Entered text: ${disqualificationReason.freeText}`
  }

  return readableReason
}
