import { Dialog } from '@headlessui/react'
import { ReactNode } from 'react'

import Icon from './Icon'

const OverlayFullScreen = ({
  children,
  onClose,
}: {
  children: ReactNode
  onClose?(): void
}) => {
  return (
    <Dialog
      onClose={() => {
        // When not testing, a respondent is not able to close the overlays.
        onClose?.()
      }}
      open
    >
      <Dialog.Panel className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-gray-5 text-gray-700 md:px-4">
        {children}

        {onClose && (
          <button
            aria-label="Close Overlay"
            className="absolute right-4 top-4 p-4"
            onClick={onClose}
            type="button"
          >
            <div aria-hidden="true" className="h-6 w-6">
              <Icon id="x-close" />
            </div>
          </button>
        )}
      </Dialog.Panel>
    </Dialog>
  )
}

export default OverlayFullScreen
